let AbstractComponent = require('../AbstractComponent.js');
let CloseButton = require('../Header/CloseButton.js');

let Components = [];
Components['cube'] = require('../Header/cube.js');
Components['clone'] = require('../Header/clone.js');
Components['tutorials'] = require('../Header/tutorials.js');
Components['javascript'] = require('../Header/javascript.js');
Components['deploy'] = require('../Header/deploy.js');
Components['login'] = require('../Header/login.js');
Components['assets'] = require('../Header/assets.js');

Components['vr'] = require('../Header/vrFullscreen.js');
Components['profile'] = require('../Header/profile.js');
Components['download'] = require('../Header/download.js');
Components['clone'] = require('../Header/clone.js');

// Turn an element into a header item
class HeaderItem extends AbstractComponent {
    
    constructor(elem) {
        super(elem);
        // add the necessary icons and panel
        this.iconElem = document.createElement('i');                
        this.iconToolTip = document.createElement('div');
        this.iconToolTip.classList.add('tooltip');
        ['hover', 'mousedown', 'mouseup','touchstart', 'touchend'].forEach( evt => {
            this.iconToolTip.addEventListener(evt, e => { console.log(e); e.preventDefault(); e.stopPropagation();});
        });        
        this.iconToolTip.selectable = false;
        this.iconToolTip.style.cursor = 'default';

        this.iconText = document.createElement('p');
        this.iconText.className = "icon-text";
        this.iconElem.appendChild(this.iconText)
	// NOTE: disabling the tooltip because it seems to be inteferring with the close button.
        //this.iconElem.appendChild(this.iconToolTip);

        // set the icon and tooltip
        this.setLabel({icon: elem.getAttribute('icon'), tooltip: elem.getAttribute('tooltip'), text: elem.getAttribute('text')});        
        


        let panelId = elem.getAttribute('panel_id');
        if (panelId) {
            let existingPanel = document.getElementById(panelId);
            existingPanel ? this.panel = existingPanel : this.addPanel(panelId);

            this.closeButton = this.elem.querySelector('.close');
            if (!this.closeButton) {
                this.closeButton = CloseButton.createComponent();
                this.panel.appendChild(this.closeButton.elem);
            }
            
            this.iconElem.addEventListener('click', evt => {
                this.show();
            });

            this.closeButton.addEventListener('click', evt => {
                this.hide();
            });
        }

        // initialize additonal component code
        Components[this.elem.id] && Components[this.elem.id].apply(this);

        // finally insert the icon
        this.elem.appendChild(this.iconElem);        
    }

    setLabel(label) {

        this.iconElem.classname = '';
        label.icon && label.icon.split(' ').forEach( c => {
            this.iconElem.classList.add(c);
        });
        this.iconToolTip.innerHTML = label.tooltip;
        // console.log("header", label)
        this.iconText.innerHTML = label.text;
    }
    
    addPanel(panelId) {
        this.panel = document.createElement('div');
        this.panel.id = panelId;
        
        this.panel.classList.add('hide');

        this.closeButton = CloseButton.createComponent();
        this.panel.appendChild(this.closeButton.elem);                        

        this.content = document.createElement('div');
        this.panel.appendChild(this.content);
        
        this.elem.appendChild(this.panel);        

    }

    hide() {
        if (this.panel) {
            this.panel.classList.add('hide');
            this.panel.classList.remove('show');
        }
    }

    show() {
        if (this.panel) {            
            this.panel.classList.add('show');
            this.panel.classList.remove('hide');
        }
    }
    
}

module.exports = HeaderItem;
