function deploy() {
  // build the inner panel
  let title = document.createElement("h1");
  title.innerHTML = "Share your VR world with your friends and family.";

  let fsUrl = document.createElement("div");
  fsUrl.id = "fsUrl";
  this.fsUrl = fsUrl;

  this.copyLink = document.createElement("button");
  this.copyLink.innerHTML = "Copy URL";

  this.panel.appendChild(title);
  this.panel.appendChild(fsUrl);
  this.panel.appendChild(this.copyLink);

  this.injectFullScreenLink = injectFullScreenLink.bind(this);
}


function copyToClipboard(data) {
  if (window.parent) window.parent.copyToClipboard(data);
}

function injectFullScreenLink(storageId) {
  if (!storageId) {
    console.warn("No storage id specified, cannot inject");
    return;
  }

  if (this.fsUrl.innerHTML.length == 0) {
    let searchParams = new URLSearchParams(window.location.search);
    let param = searchParams.get("lesson");

    let fsUrl = "env3d.html?id=" + storageId + "&realtime=true";
    if (getURLParam("module")) {
      fsUrl += `&module=${getURLParam("module")}`;
    }

    this.fsUrl.innerHTML =
      '<h2> <a target="_blank" href="' + fsUrl + '">Check it in full screen mode before sending</a></h2>';

    this.copyLink.addEventListener("click", () => {
      copyToClipboard("https://" + window.location.host + "/" + fsUrl)
    })
    // var qrcode = new QRCode(this.panel, {
    //           text: "https://" + window.location.host + "/" + fsUrl
    // });    
  }
}

module.exports = deploy;
