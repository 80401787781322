const AbstractComponent = require("../AbstractComponent.js");

const CardWithPicture = require("./CardWithPicture");

// const { checkoutRedirect } = require("./stripe");


class CardsContainer extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = document.querySelector(`.${elem.className}`);

    this.cardDivs = [];
    // this.savedData = {};
    // this.error = false;
  }
  
  setupElements(lessons, data, heading = "Modules", listeningEvent = null) {
    // set up container div
    // this.elem.classList.add("container")

    // set up header for the container
    let header = document.createElement("h2");
    header.innerHTML = heading;
    this.elem.appendChild(header);

    let listContainer = document.createElement("div");
    listContainer.className = "moduleContainer";

    let list = document.createElement("div");
    list.className = "row";

    listContainer.appendChild(list);
    this.elem.appendChild(listContainer);
    


    lessons.forEach((lesson, index) => {
      // attract information
      let sku = lesson.sku;
      let link;

      this.cardDivs[index] = document.createElement("div");
      // create card element
      let card = new CardWithPicture(this.cardDivs[index]);
      
      // if (!lesson.isPurchased) {
      //   /* 
      //     Go to payment
      //   */
      //   this.cardDivs[index].className = "item col-12";

      //   card.addEventListener("click", (e) => {
      //     e.preventDefault();

      //     if(e.target.nodeName === "A") {
      //       let paymentComp = Component.PaymentCard.createComponent(document.querySelector(".payment"));

      //       paymentComp.fetchPaymentInfo([sku], 'cad')
      //     }

      //   })
      // } else {
      //   /*
      //    Lesson with progress data
      //   */

      // }

      this.cardDivs[index].className = "item col-12 col-lg-3 col-xl-4";
      if(data && data[`${lesson.url}`] !== undefined) {
        link = `${window.location.origin}/?module=${lesson.module}&id=${data[`${lesson.url}`].sid}`;
        
      } else {
        link = `${window.location.origin}/?module=${lesson.module}`;
      }

      card.setupElements(link, lesson);

      /*
        Event listener for link items
      */
     card.addEventListener("click", (e) => {
      e.preventDefault();
      if(e.target.nodeName === "A") {
        if(typeof listeningEvent !== 'function') {
          //remove saved storageId in localStorage
          localStorage.removeItem('storageId');  
          window.location.href = this.cardDivs[index].querySelector("a").href;
        } else {
          listeningEvent(e, sku);
        }
      }


               
    });

      list.appendChild(this.cardDivs[index]);
    });
  }
}

module.exports = CardsContainer;