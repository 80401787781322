const AbstractComponent = require("../AbstractComponent.js");
const signIn = require("./login.js");
const signInWithGoogle = signIn.signInWithGoogle;
const signInWithMail = signIn.signInWithMail;

const signUp = require("./signup.js");
const signUpWithMail = signUp.signUpWithMail;

const forgot = require("./forgot")
const sendForgetPasswordEmail = forgot.sendForgetPasswordEmail;

class Auth extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.isLogin = elem.id === "login" ? true : false;
    this.form = document.createElement("form");
    this.label = [];
    this.input = [];
    this.button = [];

    /**
     * * Header section
     */
    this.header = document.createElement("h2");
    this.header.innerHTML = this.isLogin ? "Login" : "Sign up";
    this.form.appendChild(this.header);

    /**
     * * Input fields
     */
    // Can add more fields for signup if needed
    let form = this.isLogin ? ["Email", "Password"] : ["Email", "Password", "Repeat Password"]
    form.forEach((label, index) => {
      this.label[index] = document.createElement("label");
      this.label[index].innerHTML = label;

      this.input[index] = document.createElement("input");
      this.input[index].type = label.includes("Password") ? "password" : "text";

      this.form.appendChild(this.label[index]);
      this.form.appendChild(this.input[index]);
      this.form.appendChild(document.createElement("br"));
    });
    
    //* Check box for term of service and privacy policy
    if (!this.isLogin) {
      let checkboxDiv = document.createElement("div");
      checkboxDiv.className = "checkbox";

      this.checkbox = document.createElement("input");

      let checkboxLabel = document.createElement("label");
      this.checkbox.setAttribute("type", "checkbox");
      this.checkbox.setAttribute("name", "checkbox");

      checkboxLabel.innerHTML = `By clicking the button below, you agree to 
        <a href="terms-of-service.html" target="_blank">Terms of Service</a> and 
        <a href="https://www.c3d.io/codeca-privacy-policy" target="_blank">Privacy Policy</a>`;
      checkboxLabel.setAttribute("for", "checkbox");

      checkboxDiv.appendChild(this.checkbox);
      checkboxDiv.appendChild(checkboxLabel);
      this.form.appendChild(checkboxDiv);
    }

    this.errorDiv = document.createElement("div");
    this.errorDiv.className = "text-danger";
    this.form.appendChild(this.errorDiv);

    //* submit buttons
    ["email", "Google"].forEach((elem, index) => {
      this.button[index] = document.createElement("button");
      switch (index) {
        case (index = 0):
          if (this.isLogin === true) {
            this.button[index].innerHTML = "Login";
          } else {
            this.button[index].innerHTML = "Sign up";
          }
          break;
        case (index = 1):
          this.button[index].innerHTML = `Login with ${elem}`;
          break;
        default:
          break;
      }
      this.button[index].addEventListener("click", evt => {
        this.handleButton(index, evt);
      });
      this.form.appendChild(this.button[index]);
    });

    //! NOTICE FOR CHILDREN 
    let text = document.createElement("div");
    text.id = "small";
    text.innerHTML = "* Due to Google account restriction, children under 13 cannot use Login with Google. Please use email address to sign up.";
    this.form.appendChild(text);
    this.form.appendChild(document.createElement("br"));

    //* Switch between login and signup
    this.isLogin
      ? this.form.appendChild(
        document.createTextNode("Don't have an account? ")
      )
      : this.form.appendChild(
        document.createTextNode("Already has an account? ")
      );

    this.switch = document.createElement("a");
    this.switch.innerHTML = this.isLogin ? "Signup here!" : "Login here!";
    this.switch.href = this.isLogin
      ? `${window.location.origin}/signup.html`
      : `${window.location.origin}/login.html`;

    this.form.appendChild(this.switch);

    this.form.appendChild(document.createElement("br"));

    //* Forget password
    this.forget = document.createElement("a");
    this.forget.innerHTML = "Forget password?";

    // Forget password UI
    let forgetForm = document.createElement("form");

    let formContent = document.createElement("div");
    formContent.innerHTML = `<h2>Forget Password</h2>
                                <p>Please enter the email address that you used to register for c3d.io. We will send an email to change your password</p>`      
    forgetForm.appendChild(formContent);

    let forgetLabel = document.createElement("label");
    forgetLabel.innerHTML = "Email";
    forgetForm.appendChild(forgetLabel);

    let forgetPassInput = document.createElement("input");
    forgetForm.appendChild(forgetPassInput);

    let errorDiv = document.createElement("div");
    errorDiv.id = "small";
    forgetForm.appendChild(errorDiv);

    let forgetPassSubmit = document.createElement("button");
    forgetPassSubmit.innerHTML = "Submit";
    forgetForm.appendChild(forgetPassSubmit);

    forgetForm.appendChild(document.createElement("br"));
    forgetForm.appendChild(document.createElement("br"));

    let back = document.createElement("a");
    back.innerHTML = "< Back";
    back.href="#";
    forgetForm.appendChild(back);

    back.addEventListener("click", (e) => {
      e.preventDefault();
      this.form.style.transform = "translateX(0)";
      forgetForm.style.transform = "translateX(0)";
    })

    forgetPassSubmit.addEventListener("click", (e) => {
      e.preventDefault();
      sendForgetPasswordEmail(forgetPassInput.value, errorDiv, forgetPassSubmit)
      .then(isSuccess => {
        if(isSuccess) {
          forgetPassSubmit.style.display = 'none'
          forgetPassInput.style.display = 'none';
          forgetLabel.style.display = 'none';
          formContent.innerHTML = `<h2>Forget Password</h2>
                                <p>An email has been sent to your email address. Please check your inbox and follow the instruction.</p>`
        } 
      })

    })

    this.forget.addEventListener("click", (e) => {
      e.preventDefault()
      this.form.style.transform = "translateX(calc(-100% - 100px))";
      forgetForm.style.transform = "translateX(calc(-100% - 100px))";
    })
    this.forget.href = `#`;
    this.form.appendChild(this.forget);

    this.elem.appendChild(this.form);
    this.elem.appendChild(forgetForm);


    this.init();
    // End of contructor
  }

  init() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.emailVerified) {
          localStorage.setItem("userId", user.uid)
          window.location.href = `${window.location.origin}/portal.html`;
        } else {
          window.location.href = `${window.location.origin}/verification.html`;
        }
      }
    });
  }

  handleButton(index, e) {
    e.preventDefault();

    switch (index) {
      case (index = 0):
        if (this.isLogin) {
          signInWithMail(this.input[0].value, this.input[1].value, this.errorDiv);
        } else {
          if(this.input[1].value === this.input[2].value) {
            if (this.checkbox.checked) {
              signUpWithMail(this.input[0].value, this.input[1].value, this.errorDiv);
            } else {
              alert(
                "Please check our Terms of Service and Privacy Policy to process."
              );
            }
          } else {
            alert(
              "Password does not match."
            );
          }
        }
        break;
      case (index = 1):
        if (this.isLogin) {
          signInWithGoogle(this.errorDiv);
        } else {
          if (this.checkbox.checked) {
            signInWithGoogle(this.errorDiv);
          } else {
            alert(
              "Please check our Terms of Service and Privacy Policy to process."
            );
          }
        }
        break;
      default:
        break;
    }
  }
}
module.exports = Auth;
