function profile() {
  this.render = render.bind(this);

  if(window.location.href === `${window.location.origin}/profile.html`) {
    this.elem.classList.add("active")
  }

  const goToProfile = document.createElement("div")

  goToProfile.innerHTML = `<a href="${window.location.origin}/profile.html">Manage Subscription</a>`


  this.panel.appendChild(goToProfile)

  const logout = document.createElement("div")
  logout.innerHTML = `<a href=#>Sign out</a>`

  logout.addEventListener("click", () => {
    localStorage.removeItem('storageId');
    localStorage.removeItem('userId');

    firebase.auth().signOut();

  })

  this.panel.appendChild(logout)
}



function render(user) {
  // here we have a valid user
  // this.setLabel({ icon: 'fas fa-user', tooltip: 'user profile', text: 'User' });

  // this.elem.querySelector("i").style.display = "none";

  this.img = document.createElement("img");
  
  this.img.src = user && user.photoURL ? user.photoURL : 'media//placeholder-user.png';
  this.img.width = 30;
  this.img.height = 30;

  this.iconElem.appendChild(this.img);

  this.iconElem.className = "";

  this.iconElem.querySelector(".icon-text").style.display = "none";

  let nameDiv = document.createElement("p");
  nameDiv.innerHTML = `<i class="fas fa-circle"></i>online`;

  this.elem.appendChild(nameDiv);
}




module.exports = profile