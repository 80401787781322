const AbstractComponent = require("../AbstractComponent");
const { createStripeCustomer } = require("./createStripeCustomer");

class CheckoutForm extends AbstractComponent {
  constructor(elem) {
    super(elem);
    this.stripe;
    this.customer;
    this.card;
    this.chosen;
  }

  async fetchInfo() {
    try {
      const result = await createStripeCustomer()
  
      this.customer = result.customer;
      this.stripe = Stripe(result.publishableKey);
  
    } catch(err) {
      console.log(err)
    }
  }

  async init() {
    await this.fetchInfo();

    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    let elements = this.stripe.elements();

    this.cardElement = elements.create("card", { 
      style: style,
      hidePostalCode: true
    });
    this.cardElement.mount("#card-element");


    this.cardElement.on('focus', () => {
      let el = document.getElementById('card-errors');
      el.classList.add('focused');
    });

    this.cardElement.on('blur', () => {
      let el = document.getElementById('card-errors');
      el.classList.remove('focused');
    });

    this.cardElement.on('change', (event) => {
      this.showCardError(event);
    });

    let paymentForm = document.getElementById('subscription-form');

    paymentForm.querySelector("button").disabled = false

    paymentForm && paymentForm.addEventListener("submit", e => {
      e.preventDefault()
      this.changeLoadingStatePrices(true);

      // If a previous payment was attempted, get the lastest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus'
      );

      if (latestInvoicePaymentIntentStatus === 'requires_payment_method' || this.latestInvoice) {
        const invoiceId = localStorage.getItem('latestInvoiceId');
        const isPaymentRetry = true;
        // create new payment method & retry payment on invoice with new payment method
        this.createPaymentMethod({
          cardElement: this.cardElement, 
          customerId: this.customer.id, 
          priceId: this.chosen,
          invoiceId: invoiceId ? invoiceId : this.latestInvoice,
          isPaymentRetry
        })
      } else {
        // create new payment method & create subscription
        this.createPaymentMethod({ 
          cardElement: this.cardElement,
          customerId: this.customer.id, 
          priceId: this.chosen
        });
      }
    });
  }

  showCardError(event) {
    let displayError = document.getElementById('card-errors');
    this.changeLoadingStatePrices(false);
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  }
  
  createPaymentMethod({cardElement, customerId, priceId, invoiceId, isPaymentRetry}) {
    return this.stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          this.showCardError(result.error);
        } else {
          if (isPaymentRetry) {
            // Update the payment method and retry invoice payment
            this.retryInvoiceWithNewPaymentMethod({
              customerId: customerId,
              paymentMethodId: result.paymentMethod.id,
              invoiceId: invoiceId,
              priceId: priceId,
            });
          } else {
            // Create the subscription
            this.createSubscription({
              customerId: customerId,
              paymentMethodId: result.paymentMethod.id,
              priceId: priceId,
            });
          }
        }
      });
  }
  
  async createSubscription({ customerId, paymentMethodId, priceId }) {
    let token = await firebase.auth().currentUser.getIdToken()

    return (
      fetch(`${cloudURL}/create-subscription`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          console.log("payment result", result);
          // result.status === "active" ? window.location.href = `${window.location.origin}/profile.html` : null
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction.bind(this))
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod.bind(this))
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.showCardError(error);
        })
    );
  }

  onSubscriptionComplete(result) {
    // Payment was successful.
    console.log("onSubscriptionComplete", result);
    console.log("success")
    result.subscription.status === "active" ? window.location.href = `${window.location.origin}/portal.html` : null
  }


  handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    console.log("handleRequiresPaymentMethod")
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      console.log("handleRequiresPaymentMethod", subscription.status)
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      console.log("requires_payment_method")
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    console.log("handlePaymentThatRequiresCustomerAction")

    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
  
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;
  
    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {

      return this.stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          console.log(result);

          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  async retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) {
    let token = await firebase.auth().currentUser.getIdToken()

    return (
      fetch(`${cloudURL}/retry-invoice`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction.bind(this))
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete.bind(this))
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.showCardError(error);
        })
    );
  }

// Show a spinner on subscription submission
changeLoadingStatePrices(isLoading) {
  if (isLoading) {
    document.querySelector('#button-text').classList.add('hidden');
    document.querySelector('#loading').classList.remove('hidden');

    document.querySelectorAll('#pricing button').forEach(btn => {
      btn.disabled = true;
    })

  } else {
    document.querySelector('#button-text').classList.remove('hidden');
    document.querySelector('#loading').classList.add('hidden');


    document.querySelectorAll('#pricing button').forEach(btn => {
      btn.disabled = false;
    })
  }
}



}



module.exports = CheckoutForm;