let AbstractComponent = require("../AbstractComponent.js");
let apiLocation = myLocation + "/progress/";

function createTitle(callback) {
  let e = document.createElement("div");
  e.style.fontSize = "1.4em";
  e.style.paddingTop = "10px";
  callback.call(this, e);
  return e;
}

function createButton(callback) {
  let e = document.createElement("button");
  callback.call(this, e);
  return e;
}

class Lesson extends AbstractComponent {
  constructor(elem) {
    super(elem);
    this.uid = getURLParam("user") ? getURLParam("user") : localStorage.getItem("userId")
    if (!this.titleNode) {
      this.setupElements(elem);
      this.currentStep = 0;
      this.lesson = [];
      this.numCheck = 0;
    }
  }

  fetchDataFromServer(module, uid) {  
    return new Promise(function(resolve, reject) {
      database.ref(`/progress/${uid}/${module}`).once('value', snapshot => {
        console.log("Response from fetch: ", snapshot.val())
        resolve(snapshot.val());
      }, error => {
        console.log(error)
        reject(error);
      })
    })

  }

  sendDataToServer(module, uid) {
    database.ref(`/progress/${uid}/${module}`).set({
      sid: getURLParam("id"),
      step: this.currentStep,
      lesson: "Lesson1"
    })
  }

  // accepts a json formatted lesson and saved step; and present it
  // in the element window
  setLesson(lessonJson, step = 0) {
    this.elem.style.display = "grid";
    this.currentStep = step;
    this.savedStep = step;
    this.lesson = lessonJson;
    this.total = lessonJson.length;
    this.refresh(false);
  }

  fetchSavedLesson() {
    return new Promise((resolve, reject) => {
      this.fetchDataFromServer(getURLParam("module"), this.uid).then(
        data => {
          if (data !== null) {
            //switch to saved workspace
            console.log("set to saved world", data);
            setURLParam("id", data.sid);
          } else {
            //save progress and storageId to Firebase
            // localStorage.removeItem('storageId');               
            this.sendDataToServer(
              getURLParam("module"), 
              this.uid
            );
          }
          this.data = data;
          resolve(data);
        }
      );
    });
  }

  fetchLesson(url) {
    // fetch from database
    this.fetchSavedLesson().then(data => {
      return new Promise((resolve, reject) => {
        // fetch lesson from url
        fetch(url)
          .then(response => {
            return response.text();
          })
          .then(text => {
            let l = eval(text);
            data ? this.setLesson(l, data.step) : this.setLesson(l);
            resolve(this);
          })
          .catch(error => {
            console.log(error)
          })
      })
    });
  }


  //display video/img when click on a link
  displayHint(elem) {
    let isHinted = false;
    let parent = elem.parentNode

    for (var i = 0; i < parent.children.length; i++) {
      if(parent.children[i].className==="hint") {
        parent.removeChild(parent.children[i])
        isHinted = true;
      }
    }
    if(!isHinted) {
      let src

      if(elem.getAttribute("videoId")) {
        src = elem.getAttribute("videoId")

        let iframe = document.createElement("iframe");

        iframe.className = "hint"
        iframe.setAttribute("src", `https://www.youtube.com/embed/${src}?rel=0&enablejsapi=1`);
        iframe.setAttribute("allowfullscreen", true);
        iframe.setAttribute("frameborder", true);

        elem.parentNode.appendChild(iframe)
      } else if (elem.getAttribute("imgSrc")){
        src = elem.getAttribute("imgSrc")

        let img = document.createElement("img");
        img.className = "hint"

        img.setAttribute("src", `https://jeannguyenca.github.io/env3d-lessons/${src}`);

        elem.parentNode.appendChild(img)
      } else {
        src = elem.getAttribute("localImgSrc")

        let img = document.createElement("img");
        img.className = "hint"

        img.setAttribute("src", `modules/${src}`);

        elem.parentNode.appendChild(img)
      }


      
    }
  }

  // update the lesson
  refresh(isBack) {

    console.log("step", this.currentStep, this.savedStep, isBack)
    // if (this.currentStep >= this.lesson.length) {
    //   // lesson is finished
    //   // this.elem.style.display = "none";
    //   return;
    // }

    let step = this.lesson[this.currentStep];
    this.titleNode.innerHTML = step && step.title !== undefined ? step.title : "";
    this.insTitle.innerHTML = `Step: ${this.currentStep + 1}/${this.lesson.length}`

    // if the step only has 1 instruction or only contains a video, don't make it a list
    if(step) {
      if (step.text.length === 1) {
        this.textNode.innerHTML = step.text;
      } else {
        let tutorial = "<ul>";
        step.text.forEach(line => {
          tutorial += `<li>${line}</li>`
        })
        tutorial += "</ul>"
        this.textNode.innerHTML = tutorial;
      }
    }

    let displayHint = this.displayHint;

    // listen to link on click
    document.querySelector(".insContainer a") && document.querySelector(".insContainer a").addEventListener("click", function(e) {
      e.preventDefault()
      displayHint(this);
    }) 
    
    if(isBack === false || isBack === undefined) { //if click next button
      // console.log("saved step:", this.savedStep);
      // try condition

      console.log("blocks step:", isBack)
      let gotoNext = false;
      try {
        if(step) {
          if (!step.condition) {
            gotoNext = true;
          } else {
            gotoNext = step.condition.call();
          }
        }

      } catch (err) {
        console.log(err);
        gotoNext = false;
      }

      // if the condition is correct
      if (gotoNext) {
        this.nextButton.style.display = "flex";

        setTimeout(() => {
          this.nextButton.style.transitionDuration = "0.1s";
          this.nextButton.style.opacity = 0;
        }, 0);

        setTimeout(() => {
          if (this.currentStep >= this.lesson.length - 1) {
            this.nextButton.style.display = "none";
          }
          this.nextButton.style.transitionDuration = "0.5s";
          this.nextButton.style.opacity = 100;
        }, 500);
      } else {
        this.nextButton.style.display = "none";
        this.nextButton.style.opacity = 0;
      }

      // if first step - cannot go back
      if(this.currentStep == 0) {
        // console.log("step", this.currentStep, this.savedStep)
        this.backButton.disabled = true;
        this.backButton.style.opacity = 0;
      } else {
        this.backButton.disabled = false;
        this.backButton.style.opacity = 1;
      }  
    } else {
      console.log("step") 

      if (this.currentStep >= this.lesson.length - 1) {
        this.nextButton.style.display = "none";
      } else {
        if (this.currentStep < this.savedStep) {
          this.nextButton.style.display = "block";
          this.nextButton.style.opacity = 100;
        } else {
          this.nextButton.style.display = "none";
        }
      }
      
      // if first step - cannot go back
      if(this.currentStep == 0) {
        // console.log("step", this.currentStep, this.savedStep)
        this.backButton.disabled = true;
        this.backButton.style.opacity = 0;
      } else {
        this.backButton.disabled = false;
        this.backButton.style.opacity = 1;
      }      
    }
    
  }


  setupElements(elem) {
    // hide this in the beginning
    // elem.style.display = "none";

    // positioning lesson div - can be more dynamic
    elem.style.position = "absolute";
    elem.style.top = `calc(35% + 95px)`;
    elem.style.right = `20px`;

    document.body.appendChild(this.elem);

    //create Instruction header
    let insTitle = document.createElement("h2");
    insTitle.innerHTML = `Step`;
    this.insTitle = insTitle;
    this.elem.appendChild(insTitle);

    //control Div
    let controlDiv = document.createElement("div");
    controlDiv.className = "controlDiv";

    this.elem.appendChild(controlDiv);

    //reset button
    let resetButton = createButton(e => {
      controlDiv.appendChild(e);
    });
    resetButton.innerHTML = `<i class="fas fa-undo-alt"></i>`;
    resetButton.className = "resetButton";

    resetButton.addEventListener("click", () => {
      //confirm to reset
      let confirming = confirm("Are you sure you want to reset? You will lose your progress of this lesson.");

      if(confirming) {
        //set current step to 0
        this.currentStep = 0;
        this.savedStep = 0;

        //save progress when clicking reset button
        this.sendDataToServer(
          getURLParam("module"),
          this.uid)
        // if (firebase.auth().currentUser) {
        //   firebase
        //     .auth()
        //     .currentUser.getIdToken()
        //     .then(auth => {
              
        //       );
        //     });
          this.refresh(false);
        // }
      }

    });


    //help button
    let helpButton = createButton(e => {
      controlDiv.appendChild(e);
    });
    helpButton.innerHTML = `
      <a 
        target="_blank"
        href="https://www.c3d.io/solution/#${getURLParam("module")}">
        <i class="fas fa-question"></i>
      </a>
      `;
    helpButton.className = "helpButton";

    //minimize button
    let minimizeButton = createButton(e => {
      controlDiv.appendChild(e);
    });
    minimizeButton.innerHTML = `<i class="fas fa-compress"></i>`;
    minimizeButton.className = "minimizeButton";

    minimizeButton.addEventListener("click", () => {
      if (insDiv.id !== "hidden") {
        insDiv.id = "hidden";
        minimizeButton.innerHTML = `<i class="fas fa-expand"></i>`;
      } else {
        insDiv.id = "";
        minimizeButton.innerHTML = `<i class="fas fa-compress"></i>`;
      }
    });

    //create Instruction div
    let insDiv = document.createElement("div");
    insDiv.className = "insContainer";
    insDiv.style.width = `35vw`;

    this.elem.appendChild(insDiv);

    let buttonDiv = document.createElement("div");
    buttonDiv.id = "buttonDiv"
    insDiv.appendChild(buttonDiv);

    //instruction elements - title
    this.titleNode = createTitle(e => {
      insDiv.appendChild(e);
    });

    let textNode = document.createElement("div");
    this.textNode = textNode;
    textNode.style.marginTop = "10px";
    textNode.style.width = "100%";
    textNode.style.textAlign = "left";
    insDiv.appendChild(textNode);

    
    
    //next button 
    this.backButton = createButton(e => {
      buttonDiv.appendChild(e);
    });
    this.backButton.innerHTML = "Back";
    this.backButton.id="back";


    this.backButton.addEventListener("click", () => {
      this.currentStep -= 1;
      this.refresh(true);
    });

    //next button 
    this.nextButton = createButton(e => {
      buttonDiv.appendChild(e);
    });
    this.nextButton.innerHTML = "Next";
    this.nextButton.id="next"

    this.nextButton.addEventListener("click", () => {
      this.currentStep += 1;
      console.log("blocks", this.currentStep, this.savedStep)
      //save progress when clicking next button
      if(this.currentStep === this.savedStep) {
        this.refresh(false);
      } else if(this.currentStep > this.savedStep) {
        if (firebase.auth().currentUser) {
          firebase
            .auth()
            .currentUser.getIdToken()
            .then(auth => {
              this.sendDataToServer(
                getURLParam("module"),
                this.uid,
                auth
              );
            });
        }
        this.savedStep = this.currentStep
        this.refresh(false);

      } else {
        this.refresh(true);

      }
      
    });



  }
}

module.exports = Lesson;