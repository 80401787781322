const subscriptionRef = "subscription";
const loginPage = `${window.location.origin}/login.html`
const subscriptionPage = `${window.location.origin}/subscription.html`
const profilePage = `${window.location.origin}/profile.html`
const portalPage = `${window.location.origin}/portal.html`

let res,
  currentUser,
  stripeCustomerId,
  expiredBy, // use this to check if the expiration date is due
  paid, // use this to check if invoice is paid. Even if end date is not due, if the user did not paid, direct them to their profile page to perform payment update
  today = Date.now(),
  type, //monthly or annually
  subscriptionId,
  status, //active or expired
  latestInvoice

const isLogin = async () => {

  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        if (window.location.pathname !== "/") {
          resolve()
        }
        window.location.href = loginPage
      }
      resolve(user)
    })
  })
}

const isSubscripted = async () => {
  const whichOrigin = getURLParam("class");

  // check if the user login with classroom account
  if (whichOrigin) {
    return;
  }

  currentUser = await isLogin()

  return new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;

    // retrieve the latest payment history to determine if the user has a valid subscription
    database.ref(`${subscriptionRef}/${currentUser.uid}/history`)
      .orderByChild('savedTimestamp')
      .limitToLast(1)
      .on('value', snapshot => {

        // if their isn't any snapshot, this is a new user
        if (!snapshot.exists() || !snapshot.val()) {
          handleRedirect({ isNew: true })
        } else {
          // if there is any issue retrieving the date, considering them as a new user
          try {
            let responseType = Object.values(snapshot.val())[0].type
            let data = Object.values(snapshot.val())[0].data

            stripeCustomerId = data.customer;

            if (responseType.includes("invoice")) {
              // if the user update the subscription, the lines will have more than 1 object. We want to use the last one to get the correct type
              if(responseType === "invoice.payment_failed" && window.location.pathname !== "/profile.html") {
                handleRedirect({ isExpired: true })
              }
              expiredBy = data.lines.data[data.lines.data.length - 1].period.end
              paid = data.paid ? data.paid : false
              type = data.lines.data[data.lines.data.length - 1].metadata.description
              subscriptionId = data.lines.data[data.lines.data.length - 1].subscription
              status = data.status === "paid" ? "active" : "inactive"
              latestInvoice = data.id

            } else if (responseType.includes("subscription.update")) {
              expiredBy = data.current_period_end
              paid = true
              type = data.metadata.description
              subscriptionId = data.id

              status = data.cancel_at ? "cancelled" : data.status
            } else {
              handleRedirect({isNew: true})
            }

          } catch (err) {
            console.log(err)
          }

          // if expiredBy and paid does not exist, perform redirect
          if (!expiredBy || !paid) {
            console.info(`Expired by ${expiredBy}\nPaid ${paid}`)
            handleRedirect({});
          }

          // if subscription is not expired but the payment is not paid
          else if (expiredBy * 1000 < today || !paid) {
            console.info(`Expired by ${expiredBy}\nPaid ${paid}`)
            handleRedirect({ isNotPaid: true })
          }

          // subscription expired
          else if (expiredBy * 1000 < today) {
            console.info(`Expired by ${expiredBy}\nPaid ${paid}`)
            handleRedirect({ isExpired: true })
          }
          
          if (status === "active") {
            console.info(`Status ${status}\n`)
            if (window.location.pathname === "/subscription.html") {
              window.location.href = profilePage
            }
          }

          resolve(conductResolve());
        }


      }, error => {
        console.log(error)
      })
  })


}

const handleRedirect = (
  { isNew = undefined,
    isExpired = undefined,
    isNotPaid = undefined
  }) => {

  console.log("redirect", isNew, isExpired, isNotPaid)
  isNew && window.location.pathname !== "/subscription.html" ? window.location.href = subscriptionPage : res()

  isExpired || isNotPaid && window.location.pathname !== "/profile.html" ? window.location.href = profilePage : res()


}

const conductResolve = () => {
  return {
    currentUser,
    stripeCustomerId,
    expiredBy,
    paid,
    type,
    subscriptionId,
    status,
    latestInvoice
  }
}



module.exports = { isSubscripted, isLogin };
