let shortid = require("shortid");

function login() {
  let user = document.createElement('div');
  user.className = 'user';
  user.id = 'user';

  let topUser = document.createElement('div');
  topUser.className = 'topUser';

  let img = document.createElement('img');
  img.src = 'media//placeholder-user.png';
  img.width = 100;
  img.height = 100;

  let signOut = document.createElement('button');
  signOut.innerHTML = 'sign out';
  this.signOutButton = signOut;

  let createWorld = document.createElement('button');
  createWorld.innerHTML = 'create new world';
  this.createWorldButton = createWorld;

  topUser.appendChild(img);
  topUser.appendChild(signOut);
  topUser.appendChild(createWorld);

  user.appendChild(topUser);

  let storageDiv = document.createElement('div');
  storageDiv.className = "storageDiv";

  user.appendChild(storageDiv);
  this.user = user;

  this.panel.appendChild(user);

  this.userLogin = userLogin.bind(this);
  this.render = render.bind(this);

}

function userLogin(user) {
  let currentUser = user
  /* ---------------------------------------

    Await for login process to complete before do anything else
    This will ensure all information is loaded correctly

  --------------------------------------- */

  return new Promise((resolve, reject) => {
    let userLoginPromise;
    const csrfToken = getCookie('csrfToken')
    const whichClass = getURLParam("class"),
      assignment = getURLParam("assignment"),
      whichModule = getURLParam("module"),
      student = getURLParam("student"),
      whichOrigin = getURLParam("origin");

    console.log("is teacher dashboard", whichClass, student, assignment, whichModule)
    /*
      Required parameter: 
        - whichClass - student classroom
        - whichStudent - student ID
        - csrfTokn - to ensure that the user is logged in the session

      If any of these parameter is missing, identify user as normal user and check if the user is logged in yet
    */
    if (!whichClass || !student || !csrfToken) {
      if (!user) {
        if(whichOrigin) {
          window.location.assign(`https://${whichOrigin}.c3d.io/login.html?retry=true`)
        } else {
          if(window.location.host.includes("canada.c3d.io")) {
            window.location.assign(`login.html`)
          }
        }

      } else {
        if (!user.emailVerified) {
          window.location.assign("verification.html")
        }
        userLoginPromise = Promise.resolve();
      }
    } else {
      // construct request body to send information to the server
      const requestBody = JSON.stringify({
        csrfToken,
        whichClass,
        assignment,
        whichModule,
        student,
      })

      /* ---------------------------------------
        Session login flow 
          - send request to the server 
            * very important to include credentials to ensure session cookies is being sent
            * the server will need to accept these cookies as well
          - receive back custom token and signin with custom token
          - remove any storageId to avoid conflict
          - resolve promise
      --------------------------------------- */
      userLoginPromise = new Promise((resolve, reject) => {
        return fetch(`${cloudURL}/createCustomToken`, {
          method: 'POST',
          credentials: "include",
          headers: {
            'Access-Control-Allow-Origin': cloudURL,
            "Content-Type": "application/json",
          },
          body: requestBody
        })
          .then(response => {
            if (response.status !== 200) {
              window.location.assign(`https://${whichOrigin}.c3d.io/login.html?retry=true`)
            }
            return response.json();
          })
          .then(json => {
            console.log("signin with custom token")
            return firebase.auth().signInWithCustomToken(json.token)
              .then(user => {
                currentUser = user
              })
              .catch(function (error) {
                // Handle Errors here.
                console.log("Error signin with custom token", error)
                window.location.assign(classroomURL)
                // ...
              });
          })
          .then(() => {
            localStorage.removeItem("storageId")
            resolve()
          })
      })
    }

    /*
      After sign in - check module/assignment ref to populate proper world 
    */
    userLoginPromise
      .then(() => {
        firebase.auth().onAuthStateChanged((user) => {
          user.getIdTokenResult()
            .then((result) => {
              const { whichClass, student, assignment, whichModule } = result.claims
              // console.log("current user", user.uid, assignment, whichModule)

              if (assignment) {
                // populate storageId if it's student assignment
                const assignmentRef = database.ref(`/profiles/${user.uid}/classes/${whichClass}/student/${student}/assignments/${assignment}/`)

                // console.log(assignmentRef.toJSON())
                assignmentRef.once('value').then(snapshot => {
                  console.log("assignment", snapshot.val())
                  if (snapshot.val()) {
                    localStorage.setItem("storageId", snapshot.val().sid)
                  } else {
                    let storageId = shortid.generate();
                    assignmentRef.update({ sid: storageId })
                    localStorage.setItem("storageId", storageId)
                  }
                })
              } else if (whichModule || getQueryVariable("module")) {
                // ensure that normal user's module world is populate here as well
                const moduleRef = database.ref(`/progress/${whichModule ? student : user.uid}/${whichModule ? whichModule : getQueryVariable("module")}/`)

                // console.log(moduleRef.toJSON())

                moduleRef.once('value').then(snapshot => {
                  console.log("module ref", snapshot.val())
                  if (snapshot.val() && snapshot.val().sid) {
                    localStorage.setItem("storageId", snapshot.val().sid)
                  } else {
                    let storageId = shortid.generate();
                    localStorage.setItem("storageId", storageId)
                    history.pushState({}, null, window.location.href + "&id=" + storageId);
                  }
                })

              }
            })
        })

      })
      .then(() => {
        console.log("login user", currentUser, user)
        if(currentUser) {
          this.render(currentUser.user ? currentUser.user : currentUser);
          resolve(currentUser.user ? currentUser.user : currentUser);
        } 
        // else {
        //   this.render(user);
        //   resolve(user);
        // }

      })


  })

}

function render(user) {
  // here we have a valid user
  this.setLabel({ icon: 'fas fa-user', tooltip: 'user profile', text: 'User' });

  if (getQueryVariable("student")) {
    // quickly hide user icon
    this.user.closest("#login").style.display = "none"
  }

  let userDiv = this.user;

  let currentUser = getQueryVariable("student") ? getQueryVariable("student") : user.uid

  database.ref(`/users/${currentUser}/`).once('value')
    .then(snapshot => {
      console.log('profile', snapshot.val());
      return snapshot.val() ? { profile: snapshot.val() } : { profile: {} }
    })
    .then(function (u) {
      document.querySelector('#user img').src = user && user.photoURL ? user.photoURL : '';

      // must ensure that storageId is not null or undefined
      let storageId = getURLParam("id") || localStorage.getItem("storageId");
      console.log("current storage", storageId);

      // The value represents the user defined name of this world. Default to storageId
      (!u.profile[storageId] && storageId !== null) ? u.profile[storageId] = storageId : '';

      //Do not have user & name
      // create the actions -- for each world, allow clicking, renaming, and deleting 

      const objectToArray = Object.entries(u.profile).map(([sid, name]) => ({ sid, name }));
      objectToArray.sort((a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      console.log("profiles", objectToArray)

      objectToArray.forEach(aWorld => {
        let selected = '';
        // if (aWorld == storageId) selected = '<i class="fas fa-arrow-right"></i>';
        userDiv.querySelector('.storageDiv').innerHTML +=
          `<div ${aWorld == storageId ? 'class="selected"' : ""} sid=${aWorld.sid}>` +
          selected +
          '<a href="' + window.location.origin + '?id=' + aWorld.sid + '">' +
          aWorld.name +
          '</a>' +
          '<button>rename</button><button>delete</button></div>';
      });

      // attach all the necessary buttons
      userDiv.querySelectorAll('div > button').forEach(function (el) {
        el.addEventListener('click', function (e) {
          worldActionHandler(e, u, user.uid);
        });
      });

      storageId && database.ref(`/temp/${storageId}/`).once('value').then(snapshot => {
        console.log("PROFILE:", storageId, snapshot.val());
        if (!snapshot.val()) {
          return { isNew: true, storageId };
        } else if (snapshot.val().owner === currentUser) {
          return snapshot.val();
        } else {
          console.log("PROFILE: cannot add because world is already claimed");
          throw "PROFILE: cannot add because world is already claimed";
        }
      })
        .then(resp => {
          // we save the current world into the user's profile
          // this is a 2-step process, first we create an owner field under storageId
          // then we put the storageId under the owner

          console.log('PROFILE: Adding to profile', resp);
          if (resp.isNew) {
            database.ref(`/temp/${storageId}/`).set({ owner: currentUser })
            if (!getQueryVariable("assignment") && !getQueryVariable("module")) {
              database.ref(`/users/${currentUser}/`).update({
                [`${resp.storageId}`]: resp.storageId
              })
            }

          }
        });
    });

  // This would be the signout button
  this.signOutButton.addEventListener('click', function () {
    signOut()
    window.location.href = `${window.location.origin}/login.html`;
  });

  // This is the create new world button
  this.createWorldButton.addEventListener('click', function () {
    newWorld(user.uid);
  });
}

function signOut() {
  localStorage.removeItem('storageId');
  localStorage.removeItem('userId');

  // rewrite this / put these variables in this
  const csrfToken = getCookie('csrfToken')
  const whichClass = getURLParam("class"),
    student = getURLParam("student");

  if (whichClass & student & csrfToken) {
    const csrfToken = getCookie('csrfToken')

    const requestBody = JSON.stringify({
      csrfToken: csrfToken,
    })

    fetch(`${cloudURL}/sessionLogout`, {
      method: 'POST',
      credentials: "include",
      headers: {
        'Access-Control-Allow-Origin': 'api.myc3d.test',
        "Content-Type": "application/json",
      },
      body: requestBody
    })
  }

  firebase.auth().signOut();
}

// event handler to inject actions associated to each world
function worldActionHandler(e, u, uid) {
  var sid = e.target.parentElement.getAttribute('sid')
  switch (e.target.innerHTML) {
    case 'delete':
      if (confirm('Are you sure you want to delete this world?')) {
        database.ref(`/users/${uid}/${sid}`).remove()
          .then(function () {
            console.log("Remove succeeded.")
            // we remove the element from the DOM
            e.target.parentElement.parentNode.removeChild(e.target.parentElement);
          })
          .catch(function (error) {
            console.log("Remove failed: " + error.message)
          });

      }
      break;
    case 'rename':
      console.log('renaming ' + sid);
      // just going to do a quick a dirty prompt
      let newName = prompt('Please enter new name for your world');
      while (true) {
        if (newName.length < 1) {
          newName = prompt('Please enter new name for your world');
        } else {
          break;
        }
      }

      database.ref(`/users/${uid}`)
        .update({ [`${sid}`]: newName })
        .then(function () {
          console.log("Rename succeeded.")
          e.target.parentElement.querySelector('a').innerHTML = newName;
        })
        .catch(function (error) {
          console.log("Rename failed: " + error.message)
        });
      break;
  }
}

module.exports = login;
