const AbstractComponent = require("../AbstractComponent");

class UpdateSubscription extends AbstractComponent {
  constructor(elem) {
    super(elem);

  }

  init() {
    const wrappingDiv = document.createElement("div")
    wrappingDiv.className = "modalWrapper"  
    
    const modal = document.createElement("div")
    modal.className = "myModal"

    if(this.isCancelled) {
      let content = this.createCancelContent()
      // this.createCancelContent().then(content => {
        wrappingDiv.appendChild(modal)
  
        for(let element in content) {
          modal.appendChild(content[`${element}`])
        }
      // })
    } else {
      this.createChangePlanContent().then(content => {
        wrappingDiv.appendChild(modal)
  
        for(let element in content) {
          modal.appendChild(content[`${element}`])
        }
      })
    }

    
    this.elem.appendChild(wrappingDiv)

  }

  createChangePlanContent() {
    const { type, subscriptionId, stripeCustomerId } = this.user
    let content = {}

    content.currentPlan = document.createElement("p")
    content.currentPlan.innerHTML = `Current plan: ${type}`

    content.chosenPlan = document.createElement("p")
    content.chosenPlan.innerHTML = `New plan: ${this.chosen}`


    return this.retrieveUpcomingInvoice(stripeCustomerId, subscriptionId, this.chosen)
    .then(upcomingInvoice => {
      const { amount_due, next_payment_attempt} = upcomingInvoice
      const next_date = new Date(next_payment_attempt * 1000)
      const format_next_date = next_date.toLocaleDateString("en-US")

      content.upcomingDiv = document.createElement("p")
      content.upcomingDiv.innerHTML = `You will be charged $${amount_due / 100}\n on ${format_next_date}`

      content.resultDiv = document.createElement("div")
      content.resultDiv.id = "result"

      content.submitBtn = document.createElement("button")
      content.submitBtn.innerHTML = "Confirm change"


      content.cancelBtn = document.createElement("button")
      content.cancelBtn.innerHTML = "Cancel"
      content.cancelBtn.classList.add("outline")

      content.submitBtn.addEventListener("click", (e) => {
        content.submitBtn.style.display = "none"
        content.cancelBtn.style.display = "none"

        const resultDiv = document.querySelector("#result")
        resultDiv.innerHTML = `Switching to ${this.chosen} plan...`

        this.submitChange(e)
      })

      content.cancelBtn.addEventListener("click", (e) => {

        location.reload()
      })
      return content
    })

  }

  createCancelContent() {
    let content = {}

    content.confirming = document.createElement("p")
    content.confirming.innerHTML = `Are you sure you want to cancel your subscription?`

    content.resultDiv = document.createElement("div")
    content.resultDiv.id = "result"

    content.submitBtn = document.createElement("button")
    // content.submitBtn.id = "cancel-confirm-button"
    content.submitBtn.innerHTML = "Yes"

    content.cancelBtn = document.createElement("button")
    content.cancelBtn.innerHTML = "Cancel"
    content.submitBtn.classList.add("outline")

    content.submitBtn.addEventListener("click", (e) => {
      content.confirming.style.display = "none"
      content.submitBtn.style.display = "none"
      content.cancelBtn.style.display = "none"

      const resultDiv = document.querySelector("#result")
      resultDiv.innerHTML = `Cancelling your subscription`

      this.submitCancel(e)
    })

    content.cancelBtn.addEventListener("click", (e) => {
      location.reload()
    })
    return content

  }

  submitChange(e) {
    e.preventDefault()
    this.updateSubscription(this.user.subscriptionId, this.chosen).then(result => {
      console.log(result)

      const resultDiv = document.querySelector("#result")
      resultDiv.innerHTML = `Successfully switched to ${this.chosen} plan. The page will be refresh shortly...`

      setTimeout(() => {
        location.reload()
      }, 5000);
    })
  }

  submitCancel(e) {
    e.preventDefault()
    console.log("click")
    this.cancelSubscription(this.user.subscriptionId).then(result => {
      console.log(result)
      setTimeout(() => {
        location.reload()
    }, 5000);
    })
  }

  async updateSubscription(subscriptionId, priceId) {
    let token = await firebase.auth().currentUser.getIdToken()

    console.log(subscriptionId, priceId)
    return (
      fetch(`${cloudURL}/update-subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          subscriptionId: subscriptionId,
          priceId: priceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          return response;
        })
    )
  }

  async retrieveUpcomingInvoice(customerId, subscriptionId, newPriceId) {
    let token = await firebase.auth().currentUser.getIdToken()

    return fetch(`${cloudURL}/retrieve-upcoming-invoice`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        customerId: customerId,
        subscriptionId: subscriptionId,
        newPriceId: newPriceId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((invoice) => {
        return invoice;
      });
  }

  async cancelSubscription(subscriptionId) {
    let token = await firebase.auth().currentUser.getIdToken()

    return fetch(`${cloudURL}/cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        return cancelSubscriptionResponse;
      });
  }

}



module.exports = UpdateSubscription;
