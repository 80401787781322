let AbstractComponent = require('../AbstractComponent.js');

class CloseButton extends AbstractComponent {

    constructor() {
        super(document.createElement('button'));
        
        this.elem.innerHTML = 'X';
        this.elem.classList.add('close');
    }
    
    connectedCallback() {
        
    }

    attachedCallback() {
    }

    detachedCallback() {
    }

    attributeChangedCallback(attr, oldVal, newVal) {
    }

}

module.exports = CloseButton;
