function javascript() {
    let codepen = document.createElement('button');
    codepen.id = 'codepen';
    codepen.innerHTML = 'Send to CodePen';
    codepen.addEventListener('click', evt => {
        // send code to codepen
        sendToCodepen(this.clean_code.innerText);
    });

    let generated_code = document.createElement('pre');
    generated_code.id = 'generated_code';
    // we are going to hide the generated_code panel, but firefox doesn't like display:none 
    // style="position: fixed; overflow: none; width: 0; height: 0;
    generated_code.style.display = 'none';
    
    let clean_code = document.createElement('pre');
    clean_code.id = 'clean_code';
    
    this.clean_code = clean_code;
    this.generated_code = generated_code;
    
    // this.panel.appendChild(codepen);
    this.panel.appendChild(generated_code);
    this.panel.appendChild(clean_code);

    this.cleanCode = cleanCode.bind(this);
    this.generatedCode = generatedCode.bind(this);
}

function cleanCode(code) {
    code != null && (this.clean_code.innerText = code);
    return this.clean_code.innerText;
}

function generatedCode(code) {
    code != null && (this.generated_code.textContent = code);
    return this.generated_code.textContent;
}

function sendToCodepen(code) {
    if (!code) {
        console.warn('no code provided, not sending to codepen');
        return;
    }
    
    var json = {
        html:'<div id="env3d"></div>'+
             '<button id="vr">VR</button>',
        js_external:'https://beta.c3d.io/js/inflate.min.js;' +
                    'https://beta.c3d.io/js/env3d.min.js;' +
                    'https://beta.c3d.io/js/webvr-polyfill.min.js;' +
                    'https://beta.c3d.io/js/mixin.js;' +                    
                    'https://beta.c3d.io/js/codepen.js',
        css:'body {margin: 0;padding: 0;} #vr {position: fixed;top: 0;left: 0;} ' +
            '#vr[fullscreen] { display: none;}'+
            'canvas {display: block;}',
        editors: '001',
        layout: 'left',
        js: 'env.setCameraXYZ(5,5,25);\nenv.setCrosshair(true);\n' +
            code.replace(/"/g, "&apos;").replace(/'/g, "&apos;")
    };
    
    var form = document.createElement('form');
    form.action = "https://codepen.io/pen/define";
    form.method = "POST";
    form.target = "_blank";
    form.innerHTML = '<input type="hidden" name="data" value=\'' + 
                     JSON.stringify(json) + 
                     '\'>';
    document.body.append(form);
    form.submit();
    form.outerHTML = '';
}


module.exports = javascript;
