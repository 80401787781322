function download(){
    this.elem.addEventListener("click", () => {
      
      const confirming = prompt("This will save your progress on your local computer\nPlease enter the name of your file", "my-workspace-file");

      if(confirming) {
        Component.Components.blockly.saveDataLocally(confirming);
      }
    })
    return
}

module.exports = download;