require("./components/config.js");
require("./urlHelpers.js");


window["Init"] = require("./initCheck")

window["Component"] = {
  Components: {},
  Env3dWindow: require("./components/BlocklyApp/Env3dWindow.js"),
  HeaderItem: require("./components/BlocklyApp/HeaderItem.js"),
  Workspace: require("./components/BlocklyApp/Workspace.js"),
  Lesson: require("./components/BlocklyApp/Lesson.js"),
  Auth: require("./components/Auth/"),
  VRFullscreen: require("./components/Header/vrFullscreen.js"),
  Portal: require("./components/Portal/"),
  Supscription: require("./components/Subscription/"),
  PaymentCard: require("./components/StripePayment/PaymentModal"),
  Profile: require("./components/Profile/"),
  Comment: require("./components/Comment")
};
