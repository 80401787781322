let AbstractComponent = require('../AbstractComponent.js');

// Given an element, make it movable and resizable
class FlexWindow extends AbstractComponent {

    constructor(elem) {
        super(elem);

        this.drag = false;
        this.resize = false;

        this.elem.style.cursor = 'move';

        this.childNodes = [];
        this.elem.childNodes.forEach(n => {
            this.childNodes.push(n);
        });
                
        this.overlayDiv = this.createOverlayElement();
        
        this.elemResize = this.createResizeElement();
        this.elem.appendChild(this.elemResize);

        this.prevTouch = null;
        
        this.elemResize.addEventListener('mousedown', this.resizeStart.bind(this));
        this.elem.addEventListener('mousedown', this.moveStart.bind(this));
        
        this.elemResize.addEventListener('touchstart', this.resizeStart.bind(this));
        this.elem.addEventListener('touchstart', this.moveStart.bind(this));
        
        window.addEventListener('mousemove', this.dragMove.bind(this));
        window.addEventListener('mouseup', this.dragEnd.bind(this));
        
        
        window.addEventListener('touchmove', this.dragMove.bind(this));
        window.addEventListener('touchend', this.dragEnd.bind(this));
        
    }

    createOverlayElement() {
        // use this overlay to cover all the other elements when moving        
        let overlayDiv = document.createElement('div');
        overlayDiv.style.borderRadius = window.getComputedStyle(this.elem).borderRadius;
        overlayDiv.style.zIndex = 9999;
        
        return overlayDiv;
    }
    
    createResizeElement() {
        // create a resize icon, bottom right of element
        let resize = document.createElement('div');
        resize.textContent = '///';
        resize.style.position = 'absolute';
        resize.style.right = '5px';
        resize.style.bottom = '0px';
        resize.style.cursor = 'nwse-resize';
        resize.style.color = '#fff';

        return resize;

        
    }

    // various event handlers
    resizeStart(evt) {
        evt.touches && (this.prevTouch = evt);        
        this.resize = true;

        let rec = this.elem.getBoundingClientRect();
        // anchor to left and top so resize would be natural
        this.elem.style.left = rec.x + 'px';
        this.elem.style.top = rec.y + 'px';
        this.elem.style.right = '';
        this.elem.style.bottom = '';
        
        this.elem.style.borderStyle = 'solid';
        this.elem.style.borderColor = 'yellow';
        evt.preventDefault();
        // insert a div in front
        if (this.overlayDiv) {
            this.overlayDiv.style.width = rec.width + 'px';
            this.overlayDiv.style.height = rec.height + 'px';
            this.overlayDiv.style.backgroundColor = '#efa5';
            //this.elemFrame.style.zIndex = -1;
            this.elem.appendChild(this.overlayDiv);
        }
        
        this.childNodes.forEach( n => this.elem.removeChild(n) );                
        evt.preventDefault();
        evt.stopPropagation();
    }

    moveStart(evt) {
        evt.touches && (this.prevTouch = evt);
        
        this.drag = true;
        
        let rec = this.elem.getBoundingClientRect();
        // anchor to left and top so resize would be natural
        this.elem.style.left = rec.x + 'px';
        this.elem.style.top = rec.y + 'px';
        this.elem.style.right = '';
        this.elem.style.bottom = '';

        this.elem.style.borderStyle = 'solid';
        this.elem.style.borderColor = 'yellow';
        evt.preventDefault();
        
        // insert a div in front
        if (this.overlayDiv) {
            this.overlayDiv.style.width = rec.width + 'px';
            this.overlayDiv.style.height = rec.height + 'px';
            this.overlayDiv.style.backgroundColor = '#efa5';
            this.elem.appendChild(this.overlayDiv);            
        }
        
        this.childNodes.forEach( n => this.elem.removeChild(n) );        



    }
    
    dragMove(evt) {
        if (evt.touches) {
            if (this.prevTouch) {
                evt.movementX = evt.touches[0].screenX - this.prevTouch.touches[0].screenX;
                evt.movementY = evt.touches[0].screenY - this.prevTouch.touches[0].screenY;
            }
            this.prevTouch = evt;
        }
        
        if (this.drag) {
            let x = this.elem.getBoundingClientRect().left;
            let y = this.elem.getBoundingClientRect().top;
            //this.elem.style.left = '0px';
            //this.elem.style.top = '0px';
            
            //this.elem.style.left = evt.movementX + x + 'px';
            //this.elem.style.top = evt.movementY + y + 'px';
            
            this.elem.style.left = (evt.movementX + x ) + 'px';
            this.elem.style.top = (evt.movementY + y ) + 'px';
        } else if (this.resize) {
            let w = this.elem.getBoundingClientRect().width;
            let h = this.elem.getBoundingClientRect().height;
            this.elem.style.width = evt.movementX + w + 'px';
            this.elem.style.height = evt.movementY + h + 'px';
            if (this.overlayDiv) {
                this.overlayDiv.style.width = this.elem.style.width;
                this.overlayDiv.style.height = this.elem.style.height;
            }
        }        
    }

    dragEnd(evt) {
        if (this.drag || this.resize) {
            this.drag = false;
            this.resize = false;
            this.elem.style.borderStyle = '';
            this.elem.style.borderColor = '';
            this.overlayDiv && this.elem.removeChild(this.overlayDiv);
            this.childNodes.forEach( n => this.elem.appendChild(n));            
        }
    }
}

module.exports = FlexWindow;
