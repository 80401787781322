let AbstractComponent = require("../AbstractComponent.js");
let shortid = require("shortid");
let apiLocation = myLocation + "/temp/";

class Workspace extends AbstractComponent {
  // a couple of utilitiy functions
  static fetchDataFromServer(storageId) {
    return new Promise(function (resolve, reject) {
      fetch(apiLocation + "/" + storageId + ".json")
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            reject(response);
          }
        })
        .then(function (json) {
          resolve(json);
        })
        .catch(function (resp) {
          reject(resp);
        });
    });
  }

  downloadFile(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  }

  saveDataLocally(fileName){
    const xml = Blockly.Xml.workspaceToDom(this.workspace)
    const xmlString = Blockly.Xml.domToText(xml)

    const code = Blockly.JavaScript.workspaceToCode(this.workspace)

    const params = new URLSearchParams(window.location.search)

    console.log(code.replace(/\"/gm, '\\"').replace(/\n/gm, '\\n'))

    const data = {
      id: params.get('id'),
      module: params.get('module'),
      xml: xmlString,
      code: code
    }

    const fileNameOutput = fileName || params.get('id')

    this.downloadFile(fileNameOutput + '.json', JSON.stringify(data))
  }

  static sendDataToServer(storageId, workspace, code, authToken) {
    var url = apiLocation + storageId + ".json";
    if (authToken) url += "?auth=" + authToken;

    var xml = Blockly.Xml.workspaceToDom(workspace);
    var xml_text = Blockly.Xml.domToText(xml);

    let data = { xml: xml_text, code: code };

    var request = new Request(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      mode: "cors",
      body: JSON.stringify(data)
    });

    return new Promise(function (resolve, reject) {
      fetch(request)
        .then(function (response) {
          if (!response.ok) {
            console.warn("error with saving", request, response);
            reject(response);
          } else {
            resolve(response);
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  }

  

  constructor(elem) {
    super(elem);

    this.blocklyDiv = document.createElement("div");
    this.blocklyDiv.id = "blocklyDiv";
    this.elem.appendChild(this.blocklyDiv);

    this.error = false;
    this.hasInit = false;
    //this.init();
    //['resize', 'webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange']
    ["resize"].forEach(e => {
      window.addEventListener(e, this.resize.bind(this), false);
    });
  }

  // Refresh blockly workspace (used when file is uploaded or deleted)
  refreshWorkspace(){
    this.hasInit = false;
    this.blocklyDiv.innerHTML = ''

    let blockly = Component.Workspace.createComponent(
      document.querySelector("#blockly")
      )
      
    if(blockly) blockly.init()
     
  }

  // initialize the blockly workspace. needs to be called explicitly
  init() {
    // make sure init can only be called once
    if (this.hasInit) return;

    console.log("blockly init")
    this.hasInit = true;

    // choose the correct toolbox to load based on URL parameter
    // NOTE: this is such a hack, needs to rewrite
    let toolbox;
    if (["module1", "module2", "module3"].includes(getURLParam("module"))) {
      toolbox = `modules/${getURLParam("module")}/toolbox.xml`;
    } else {
      if (['classroom'].includes(getURLParam("origin"))) {
        toolbox = "toolbox_free.xml"
      } else {
        toolbox = "toolbox.xml";
      }
    }

    fetch(toolbox)
      .then(res => {
        return res.text();
      })
      .then(data => {
        let xml = new window.DOMParser().parseFromString(data, "text/xml");
        this.injectBlockly(xml);
      });
  }

  // create callback when loading is done
  onLoad(func) {
    this.onLoadCallback = func;
  }

  injectBlockly(toolboxXml) {
    this.workspace = Blockly.inject(this.blocklyDiv, {
      media: "media/",
      toolbox: toolboxXml.getElementById("toolbox"),
      zoom: {
        controls: true,
        wheel: false,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
        scrollbars: true
      },
      move: {
        scrollbars: true,
        drag: true,
        wheel: false
      },
      grid: { spacing: 20, length: 3, colour: "#ccc", snap: true },
      trashcan: true
    });

    console.log("injecting blockly", this.workspace);


    function setBlocklyScrollBars(ws) {
      Blockly.bindEvent_(ws.svgGroup_, "wheel", ws, ev => {
        let e = ev;
        const delta = e.deltaY > 0 ? -1 : 1;
        const position = Blockly.utils.mouseToSvg(e, ws.getParentSvg());
        if (e.ctrlKey) ws.zoom(position.x, position.y, delta);
        else if (ws.scrollbar) {
          let y = parseFloat(
            ws.scrollbar.vScroll.svgHandle_.getAttribute("y") || "0"
          );
          y /= ws.scrollbar.vScroll.ratio_;
          ws.scrollbar.vScroll.set(y + e.deltaY);

          let x = parseFloat(
            ws.scrollbar.hScroll.svgHandle_.getAttribute("x") || "0"
          );
          x /= ws.scrollbar.hScroll.ratio_;
          ws.scrollbar.hScroll.set(x + e.deltaX);

          ws.scrollbar.resize();
        }
        e.preventDefault();
      });
    }
    setBlocklyScrollBars(Blockly.mainWorkspace);

    // Need to add a custom theme for HAT_STYLE.
    // In the future we want to create a entire theme by setting options

    Blockly.getTheme().setBlockStyle("hat_blocks", {
      colourPrimary: "160",
      hat: "cap"
    });

    this.resize();
    this.storageId = this.getStorageIdAndPopulateWorkspace();
    //this.populateWorkspace(this.storageId);

    this.workspace.addChangeListener(this.changeListener.bind(this));
  }

  // return the current world's storage id, or create one, and also populate the workspace
  // with the storage by retrieving it from firebase.
  //
  // note: we also rewrite the url using history api
  getStorageIdAndPopulateWorkspace() {
    // retrive the storageId from local storage if it is saved
    var storageId = getQueryVariable("id") || localStorage.getItem("storageId");

    // if we have a clone id in the parameter, we create and new storageId
    // and copy the code from the cloneId
    var cloneId = getQueryVariable("clone");
    console.log("clone id", cloneId);
    if (cloneId) storageId = null;

    if (!storageId) {
      console.log("No storage ID found, generating a new one");
      storageId = shortid.generate();
    }
    // Save the storage Id to localStorage
    localStorage.setItem("storageId", storageId);

    // Copy the code if cloneId is present
    if (cloneId) {
      history.pushState({}, null, "/");
      console.log(`cloning ${cloneId}`);
      this.populateWorkspace(cloneId);
    } else {
      this.populateWorkspace(storageId);
    }

    // we have storageId for sure, push the state so URL can be bookmarked
    if (!getQueryVariable("id") && !getQueryVariable("user")) {
      if (!window.location.href.includes("?")) {
        history.pushState({}, null, "?id=" + storageId);
      } else {
        history.pushState({}, null, window.location.href + "&id=" + storageId);
      }
    }

    return storageId;
  }

  populateWorkspace(storageId) {
    Workspace.fetchDataFromServer(storageId)
      .then(
        function (data) {
          if (data && data.xml) {
            console.log("data", data.xml);
            // we extract the xml for injection into workspace, the replace is to avoid issues
            // with files saved in the older version of blockly that allows shadow variables.
            // see https://github.com/google/blockly/issues/561
            var xml = data.xml.replace(
              /shadow type="variables_get"(.*?)\/shadow/g,
              'block type="variables_get"$1/block'
            ).replace(
              /shadow type="env3d_camera_inputs"(.*?)<\/value><\/shadow>/g,
              'block type="env3d_camera_inputs"$1<\/value><\/block>'
            )
            // .replace(
            //   /shadow type="env3d_camera_inputs"(.*?)<\/next><\/shadow>/g,
            //   'block type="env3d_camera_inputs"$1<\/next><\/block>'
            // )

            try {
              Blockly.Xml.domToWorkspace(
                Blockly.Xml.textToDom(xml),
                Component.Components["blockly"].workspace
              );
              //window.camera = data.camera;
            } catch (e) {
              console.warn(e);
              this.error = true;
            }
          }
          this.onLoadCallback && this.onLoadCallback();
        }.bind(this)
      )
      .catch(this.handleServerError);
  }

  injectCodeFromServer(storageId, workspace, code, auth) {
    Workspace.sendDataToServer(storageId, workspace, code, auth)
      .then(function () {
        Component.Components["deploy"].injectFullScreenLink(storageId);
        Component.Components["vr"].injectFullScreenLink(storageId);
        // delete any warning divs
        let warning = document.querySelector(".warning");
        warning && warning.remove();
      })
      .catch(this.handleServerError);
  }

  handleServerError(resp) {
    console.log(resp);

    function getErrorMessage(code) {
      var error_messages = {
        "401": [
          `You are most likely looking at a world that belongs to someone else.`,
          `Please create a new world, or clone this one`
        ].join("\n")
      };

      return error_messages[code] || "Please refresh to reconnect";
    }

    // HACK: Create an overlay to warn users
    let message =
      [
        `Error: ${resp.statusText}`,
        `Feel free to look at the code, but you cannot modify this code,`,
        `nor can you view this world in VR`,
        ``,
        ``
      ].join("\n") + getErrorMessage(resp.status);

    if (!document.querySelector(".warning")) {
      let warningDiv = document.createElement("div");
      warningDiv.classList.add("warning");

      let messagePre = document.createElement("pre");
      messagePre.innerText = message;
      warningDiv.append(messagePre);

      if (resp.status == 401) {
        let createButton = document.createElement("button");
        createButton.innerText = "Create a new world";
        createButton.addEventListener("click", evt => {
          localStorage.removeItem("storageId");
          window.location.href = window.location.origin;
        });

        let cloneButton = document.createElement("button");
        cloneButton.innerHTML = "Clone this world";
        cloneButton.addEventListener("click", evt => {
          let cloneId = localStorage.getItem("storageId");
          localStorage.removeItem("storageId");
          window.location.href = `${window.location.origin}/?clone=${cloneId}`;
        });
        warningDiv.append(createButton, cloneButton);
      }

      document.body.append(warningDiv);
    }
  }

  changeListener(evt) {
    if (this.error) {
      console.warn("WORKSPACE: Error in fetching world from firebase");
    }

    // Auto save code to server and live reload
    if (
      (evt instanceof Blockly.Events.Change ||
        evt instanceof Blockly.Events.Move) &&
      !this.error
    ) {
      console.log(evt);
      // Generate clean code for display purposes
      window.LOOP_TRAP_COUNT = -1;
      Component.Components["javascript"].cleanCode(
        Blockly.JavaScript.workspaceToCode(this.workspace)
      );

      // Generate the safe code with proper LOOP_TRAP_COUNT
      window.LOOP_TRAP_COUNT = 0;
      let g_code = Blockly.JavaScript.workspaceToCode(this.workspace);
      Component.Components["javascript"].generatedCode(g_code);

      // if the change is from the camera block via drag of the env3d area, the block
      // is shadowed and we do not reload
      let block = Blockly.getMainWorkspace().getBlockById(evt.blockId);
      block && (block = block.getParent());

      // block && (console.log('changeListener', block, block.isShadow()));
      if (!block || block.type != "env3d_camera_inputs" || !block.isShadow()) {
        Component.Components["env3d"].reload();
      }

      var code = Component.Components["javascript"].generatedCode();
      console.log(firebase.auth().currentUser);
      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(auth => {
            this.injectCodeFromServer(
              this.storageId,
              this.workspace,
              code,
              auth
            );
          });
      } else {
        this.injectCodeFromServer(this.storageId, this.workspace, code);
      }
    }
  }

  resize(e) {
    // resize to full window
    var x = 0;
    var y = 0;
    var width = window.innerWidth;

    // HACK: had to take into account the size of the banner
    var height = window.innerHeight - 70;

    // Position blocklyDiv over blocklyArea
    this.blocklyDiv.style.left = x + "px";
    this.blocklyDiv.style.top = y + "px";
    this.blocklyDiv.style.width = width + "px";
    this.blocklyDiv.style.height = height + "px";

    Blockly.svgResize(this.workspace);
  }
}

module.exports = Workspace;
