let AbstractComponent = require("../AbstractComponent.js");

class Projects extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.error = false;
    this.init();
  }

  init() {
    let container = document.createElement("div");
    container.className = "world-container";

    this.elem.appendChild(container);

    let projectHeader = document.createElement("h2");
    projectHeader.innerHTML = "My Worlds";

    container.appendChild(projectHeader);

    let list = document.createElement("ul");
    list.className = "row";
    container.appendChild(list);

    let projectsDivs = [];

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // console.log("User:", user);
        user
          .getIdToken()
          .then(function(token) {
            // retrieve what is already there under the user profile
            return new Promise(function(resolve, reject) {
              fetch(
                myLocation+"/users/" +
                  user.uid +
                  ".json?auth=" +
                  token
              )
                .then(function(profile) {
                  return profile.json();
                })
                .then(function(json) {
                  if (!json) {
                    json = {};
                  }
                  resolve({ profile: json, token: token });
                });
            });
          })
          .then(function(u) {
            // create the actions -- for each world, allow clicking, renaming, and deleting
            Object.keys(u.profile).forEach((sid, index) => {
              projectsDivs[index] = document.createElement("li");
              projectsDivs[index].className = "list-item col-12 col-md-4";

              projectsDivs[index].innerHTML =
                `<div sid=${sid} class="card">
                  <div class="card-body">
                    ${u.profile[sid]}
                      <a href=${window.location.origin}?id=${sid}>
                        Go
                      </a>
                  </div>
                </div>`;
                list.appendChild(projectsDivs[index]);
            });
            projectsDivs[Object.keys(u.profile).length] = document.createElement("li");
          })
      }

      /* Create a new world ============ */
      let createNewContainer = document.createElement("div");
      createNewContainer.className = "create-new";
  
      this.elem.appendChild(createNewContainer);

      createNewContainer.innerHTML = `
      <a href=# onClick="newWorld(localStorage.getItem('userId'))">
        Create a VR world from scratch (for experienced coders)
      </a>`
    });
  }
}

module.exports = Projects;
