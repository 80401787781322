let AbstractComponent = require("./AbstractComponent.js");
let editor = require("./editor")

class Comment extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.class = getURLParam("class")
    this.student = getURLParam("student")
    this.assignment = getURLParam("assignment")
    this.role = getURLParam("role")

    this.editor
  }

  fetchFromServer(url){
    return new Promise(function(resolve, reject) {
      database.ref(url)
      .once('value', snapshot => {
        console.log("Response from fetch: ", snapshot.val())
        resolve(snapshot.val());
      }, error => {
        console.log(error)
        reject(error);
      })
    })
  }

  sendDataToServer(data) {
    database.ref(`/profiles/${this.uid}/classes/${this.class}/student/${this.student}/assignments/${this.assignment}/`).update({
      comments: data
    })
  }

  async setupElements() {
    let elem = this.elem
    // positioning lesson div - can be more dynamic
    elem.style.position = "absolute";
    elem.style.top = `calc(35% + 95px)`;
    elem.style.right = `20px`;

    document.body.appendChild(this.elem);


    // prepare instructions and comments
    const assignmentInstructionLink = `/profiles/${this.uid}/classes/${this.class}/assignment/${this.assignment}/`
    
    console.log('assignment instruciton params', `/profiles/${this.uid || 'none'}/classes/${this.class || 'none'}/assignment/${this.assignment || 'none'}/`)
    const inst = await this.fetchFromServer(assignmentInstructionLink)
    
    const commentLink = `/profiles/${this.uid}/classes/${this.class}/student/${this.student}/assignments/${this.assignment}/comments`
    
    const comm = await this.fetchFromServer(commentLink)

    let insDiv = document.createElement("div")
    insDiv.className = `insContainer ${this.role}`


    this.elem.appendChild(insDiv)

    const content = [
      {
        elem: 'h3',
        attr: {
          class: 'title',
        },
        innerHTML: `Project: ${inst.name}`,
        isRender: true
      },
      {
        elem: 'button',
        attr: {
          class: '',
          templateId: inst.templateId
        },
        innerHTML: "Import the starter code",
        isRender: inst.templateId ? true: false
      },
      {
        elem: 'p',
        innerHTML: inst.description,
        isRender: true
      },
      {
        elem: 'hr',
        attr: {
          class: "mt-2 mb-3"
        },
        isRender: true
      },
      {
        elem: 'h4',
        isRender: this.role === "teacher",
        innerHTML: comm ? "Edit Comment" : "Add Comment"
      },
      {
        elem: 'h4',
        isRender: this.role === "student" && comm,
        innerHTML: "Comments"
      },
      {
        elem: 'div',
        attr: {
          id: 'assignment-comment'
        },
        isTextEditor: true,
        isRender: this.role === "teacher"
      }, 
      {
        elem: 'div',
        attr: {
          id: 'assignment-comment'
        },
        isRender: this.role === "student",
        innerHTML: comm ? comm : ""
      }, 
      {
        elem: 'div',
        attr: {
          id: 'feedback'
        },
        isRender: this.role === "teacher"
      },
      {
        elem: 'button',
        attr: {
          id: !comm ? 'save' : 'edit'
        },
        innerHTML: !comm ? 'save' : 'edit',
        isRender: this.role === "teacher"
      }
    ]

    content.forEach(content => {
      const { elem, attr, innerHTML, isTextEditor, isRender } = content

      if (isRender) {
        let component = document.createElement(elem)
         
        if (attr) {
          for (const [key, value] of Object.entries(attr)) {
            component.setAttribute(key, value);
          }
        }

        component.innerHTML = innerHTML ? innerHTML : ''
        
        if (isTextEditor) {
          this.editor = this.role === "teacher" ? editor(component, comm ? comm : '') : ''
        }


        if(attr && (attr.id === 'save' || attr.id === 'edit')) {
          component.addEventListener("click", () => {
            console.log("assignment", this.editor.content.innerHTML)

            this.sendDataToServer(this.editor.content.innerHTML)

            insDiv.querySelector("#feedback").innerHTML = `Sucessfully ${attr.id === 'save' ? 'saved' : 'edited'}`
            
          })
        }
        
        if(attr && attr.templateId) {
          component.addEventListener("click",()=>{
            window.Component.Components.clone.loadWorld(attr.templateId)
            console.log(Blockly.Xml.workspaceToDom(window.Component.Components.blockly.workspace).children.length)
            
          })
        }
  
        insDiv.appendChild(component)
      }

    })

  }

}

module.exports = Comment