function vrFullscreen() {
  // build the inner panel
  let title = document.createElement("h1");
  title.innerHTML = "See your world in 360 degree VR with a cardboard headset.";

  let fsUrl = document.createElement("div");
  fsUrl.id = "fsUrl";
  this.fsUrl = fsUrl;

  this.panel.appendChild(title);
  this.panel.appendChild(fsUrl);

  this.injectFullScreenLink = injectFullScreenLink.bind(this);
}

function injectFullScreenLink(storageId) {
  if (!storageId) {
    console.warn("No storage id specified, cannot inject");
    return;
  }

  if (this.fsUrl.innerHTML.length == 0) {
    let searchParams = new URLSearchParams(window.location.search);
    let param = searchParams.get("lesson");

    let fsUrl = "env3d.html?id=" + storageId + "&realtime=true";
    if (getURLParam("module")) {
      fsUrl += `&module=${getURLParam("module")}`;
    }

    this.fsUrl.innerHTML =
      '<h2>QR code for VR experience with a mobile phone</h2>';

    let qrDiv = document.createElement("div");
    this.panel.appendChild(qrDiv);

    new QRCode(qrDiv, {
      text: "https://" + window.location.host + "/" + fsUrl
    });  

    let instruction = document.createElement("div");

    instruction.innerHTML = `
      <h3>Follow these steps to see VR world in 360 degree virtual reality.</h3>
      <ol>
        <li>Scan the QR code with phone.</li>
        <li>A web browser will open.</li>
        <li>Tap on the cardboard icon and hold the phone horizontally. The screen will split into two.</li>
        <li>Put the phone in the cardboard headset. Now you can experience your VR world.</li>
      </ol>
    `
    this.panel.appendChild(instruction)
  }
}

module.exports = vrFullscreen;
