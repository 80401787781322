const AbstractComponent = require("../AbstractComponent");

class Profile extends AbstractComponent {
  constructor(elem) {
    super(elem);
    this.cards = [];
    this.form;
    this.user;
    this.pricing;
  }

  setupElement() {
    console.log(this.user)
    
    const { photoURL, displayName } =  this.user.currentUser 
    const { expiredBy, type, status } = this.user

    let expiredDate = new Date(expiredBy * 1000)
    expiredDate = expiredDate.toLocaleDateString("en-US",{
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

    const avatarDiv = document.createElement("div")
    avatarDiv.className = "col col-md-2"

    const userImg = document.createElement("img")
    userImg.src = photoURL ? photoURL : `./media/placeholder-user.png`

    avatarDiv.appendChild(userImg)

    const profileDiv = document.createElement("div")
    profileDiv.className = "col col-md-4"

    const profileDivContent = {}

    profileDivContent.displayNameDiv = document.createElement("p")
    profileDivContent.displayNameDiv.innerHTML = displayName ? displayName : ""

    profileDivContent.subscriptionTypeDiv = document.createElement("p")
    profileDivContent.subscriptionTypeDiv.innerHTML = `Subscription type: ${type}`

    profileDivContent.expiredByDiv = document.createElement("p")
    profileDivContent.expiredByDiv.innerHTML = `Expires by: ${expiredDate}`

    profileDivContent.statusDiv = document.createElement("p")
    profileDivContent.statusDiv.innerHTML = `Status: ${status}`

    // profileDivContent.paymentMethodDiv = document.createElement("p")
    // profileDivContent.paymentMethodDiv.innerHTML = `Payment method: `

    if (status === "Inactive") {
      profileDivContent.updatePaymentBtn = document.createElement("button")
      profileDivContent.updatePaymentBtn.innerHTML = "Update payment method"
      profileDivContent.updatePaymentBtn.addEventListener("click", this.submitUpdate.bind(this))
    } else if (status === "cancelled") {

    } else {
      profileDivContent.changeBtn = document.createElement("button")
      profileDivContent.changeBtn.id="change-plan-button"
      profileDivContent.changeBtn.innerHTML = "Change plan"
      profileDivContent.changeBtn.addEventListener("click", this.changeSubscription.bind(this))
  
      profileDivContent.cancelBtn = document.createElement("button")
      profileDivContent.cancelBtn.innerHTML = "Cancel subscription"
  
      profileDivContent.cancelBtn.addEventListener("click", (e) => {
        if (!this.form) {
          this.form = Component.Supscription.UpdateSubscription.createComponent(document.querySelector("#form"))

          this.form.user = this.user;

          this.form.isCancelled = true;
          this.form.init()
        }
      })
    }



    for(let element in profileDivContent) {
      profileDiv.appendChild(profileDivContent[`${element}`])
    }
    this.elem.appendChild(avatarDiv)
    this.elem.appendChild(profileDiv)
  }

  changeSubscription(e) {
    e.preventDefault()
    
    if(!this.pricing) {
      this.pricing = Component.Supscription.Pricing.createComponent(document.querySelector("#pricing"))
      this.pricing.user = this.user

      this.pricing.setupElement()
    }
  }
  submitCancel(e) {
    e.preventDefault()
    console.log("cancel")
    this.cancelSubscription(this.user.subscriptionId).then(result => {
      console.log(result)
      
      // result.cancel_at && location.reload()
      setTimeout(() => {
        result.cancel_at && location.reload()
    }, 5000);
    })
  }

  submitUpdate(e) {
    e.preventDefault()
    
    const form = document.querySelector("#subscription-form")

    document.querySelector("#button-text").innerHTML = "Update"
    document.querySelector("#loading").innerHTML = "Updating..."

    form.classList.remove("hidden")
    this.form = Component.Supscription.CheckoutForm.createComponent(form)

    this.form.latestInvoice = this.user.latestInvoice
    this.form.chosen = this.user.type
    this.form.init()
  }


  async cancelSubscription(subscriptionId) {
    let token = await firebase.auth().currentUser.getIdToken()

    return fetch(`${cloudURL}/cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        return cancelSubscriptionResponse;
      });
  }
}

module.exports = Profile