function tutorials() {
  this.elem.addEventListener("click", () => {
    if(getURLParam("class")) {
      const url = getURLParam("role") === "teacher" ? "index.html": `portal.html?class=${getURLParam("class")}&student=${getURLParam("student")}&name=${getURLParam("name")}`; 
      window.location.href = `${classroomURL}/${url}`;
    } else {
      window.location.href = `${window.location.origin}/portal.html`;

    }
  });

  if(window.location.href === `${window.location.origin}/portal.html`) {
    this.elem.classList.add("active")
  }
  return;
}

module.exports = tutorials;
