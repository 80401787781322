function signInWithGoogle(errorDiv) {
  var provider = new firebase.auth.GoogleAuthProvider();

      firebase
      .auth()
      .signInWithPopup(provider)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        errorDiv.innerHTML = error.message;
        console.log(error)
      });
  
}

function signInWithMail(email, password, errorDiv) {
  // console.log(this.input[0].value + " " + this.input[1].value);
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      errorDiv.innerHTML = error.message;
    });
}

module.exports = { signInWithGoogle, signInWithMail };
