
function assets() {
    // simply create an iframe

    let iframe = document.createElement('iframe');
    iframe.src = 'assets.html';
    // iframe.style = 'position: absolute; left: 0; top: 0; margin:0; padding: 0; width: 100%; height: 100%; border: none;';
    
    this.panel.appendChild(iframe);

    this.panel.refreshAssets = () => {
      iframe.src += ''; 
    }
}

module.exports = assets;
