function signUpWithMail(email, password, errorDiv) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
      errorDiv.innerHTML = error.message;
    });
}

function sendVerification() {
  var user = firebase.auth().currentUser;

  var actionCodeSettings = {
    url: `${window.location.origin}/portal.html`
  };

  user
    .sendEmailVerification(actionCodeSettings)
    .then(function() {
      // Email sent.
      console.log("email sent");
    })
    .catch(function(error) {
      // An error happened.
      console.log(error)
    });
}

module.exports = { signUpWithMail, sendVerification };
