function cube() {
    console.log('creating cube', this);
    
    // the cube button doesn't have panels, simply display an env3d overlay
    this.elem.addEventListener('click', function() {
        env3d.style.display == 'none'? env3d.style.display = 'block' : env3d.style.display = 'none';
        if (env3d.style.display == 'block')
            document.querySelector('main iframe').contentWindow.location.reload();
    });
    
    
}

module.exports = cube;
