window["getURLParam"] = function(param) {
  let searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

window["compareURLParam"] = function(searchParams, param, value) {
  return searchParams.get(param) === value;
};

window["setURLParam"] = function(param, value) {
  let searchParams = new URLSearchParams(window.location.search);
  if (!compareURLParam(searchParams, param, value)) {
    searchParams.set(param, value);
    window.location.href = `${
      window.location.origin
    }/?${searchParams.toString()}`;
  }
};
