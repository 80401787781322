
class AbstractComponent {
    
    constructor(elem) {
        this.elem = elem;
        this.elem.abstractComponent = this;
    }

    addEventListener(evt, callback, options) {
        this.elem.addEventListener(evt, callback, options);
    }

    static createComponent(elem) {
	// No need to create if already created	
        let comp = elem && elem.id ? Component.Components[elem.id] : null;
	comp = comp || new this(elem);
	
        if (Component.Components) {
            elem && elem.id && (Component.Components[elem.id] = comp);
        }
        return comp;
    }
}

module.exports = AbstractComponent;
