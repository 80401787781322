function loadWorld(storageId) {
    let comp = Component.Components['blockly'];
    if (Blockly.Xml.workspaceToDom(comp.workspace).children.length > 0) {
        alert('Cannot load because you already have code written!');
    } else {    
        if (storageId && storageId.trim().length > 0) {
            if (!Blockly.Xml.workspaceToDom(window.Component.Components.blockly.workspace).children.length > 0) {
                  document.getElementById('lesson').innerHTML = ''
                }
            comp.populateWorkspace(storageId.trim());
            this.hide();
        }
    }
}

function clone() {
    // 'this' is the current element

    this.loadWorld = loadWorld.bind(this);
    
    let title = document.createElement('div');
    title.classList.add('clone-from-id')
    title.innerHTML = '<p>Clone from existing id: </p>';

    let inputWrapper = document.createElement('div')
    inputWrapper.classList.add('id-input-wrapper')
    let inputLabel = document.createElement('label')
    inputLabel.innerHTML = 'ID'
    let input = document.createElement('input');
    let cloneButton = document.createElement('button');
    cloneButton.innerHTML = 'clone';

    cloneButton.addEventListener('click', evt => {
        loadWorld.call(this, input.value.trim());        
    });

    
    inputWrapper.appendChild(inputLabel)
    inputWrapper.appendChild(input);
    inputWrapper.appendChild(cloneButton);
    title.appendChild(inputWrapper)

    let idHelp = document.createElement('div');
    idHelp.classList.add('id-help');
    idHelp.innerHTML = '<p>Where can I find the ID? <span>[?]</span></p> <div><p>You can find the ID of a world by looking at the URL of the workspace you would like to clone. For example https://canada.c3d.io/...id=<b>this-id</b>&...</p></div>';
    idHelp.addEventListener('click', evt => {
        idHelp.classList.toggle('help-open');
    })
    title.appendChild(idHelp);
    
    this.panel.appendChild(title);

    let title2 = document.createElement('div');
    title2.classList.add('clone-examples')
    title2.innerHTML = '<p>Or choose from the following examples: </p>';
    this.panel.appendChild(title2);

    [
        {id: '8an-Xg8lM', title: 'Planet'},
        {id: 'vNWYlfVI_', title: 'Collision'},
        {id: '-UEopgTHp', title: 'If'},
        {id: 'eS5ixI9zb', title: 'Parameter'},
        {id: 'HJJ-vf8FM', title: 'Catch\'em'},
        {id: 'PHMW8BYIZ', title: 'Zombies'},
    ].map((ex) => {
        let b = document.createElement('button');
        b.innerHTML = ex.title;
        b.id = ex.id;
        b.addEventListener('click', evt => {
            loadWorld.call(this, b.id);
        });
        return b;
    }).forEach((b,index) => {
        const label = document.createElement('p')
        switch(index){
            case 0:
                label.innerHTML = 'Simple' 
                title2.appendChild(label)
                break
            case 2:
                label.innerHTML = 'Intermediate'
                title2.appendChild(label)
                break
            case 4:
                label.innerHTML = 'Games'
                title2.appendChild(label)
                break
            default:
                break
        }
        
        title2.appendChild(b);
    });       

    const footNote = document.createElement('p')
    footNote.innerHTML = 'Do not use clone feature in lesson modules!'
    footNote.classList.add('clone-foot-note')
    this.panel.appendChild(footNote)
}

module.exports = clone;
