const sendForgetPasswordEmail = (email, errorDiv) => {
  return firebase
    .auth()
    .sendPasswordResetEmail(email).then(() => {
    // Email sent.
    // errorDiv.innerHTML = "An email has been sent to your email address. Please check your inbox and follow the instruction.";
    // submitBtn.style.display = "none";
    return true;
  }).catch((error) => {
    errorDiv.innerHTML = error.message;
    // submitBtn.style.display = "none";
    return false;
  });
}
module.exports = { sendForgetPasswordEmail };
