let AbstractComponent = require('../AbstractComponent');
const drawerElem = [
  {
    innerHTML: "Modules",
    id: "modulesNav",
    onClick: (e) => {
      e.preventDefault()

      document.getElementById("lessons").style.display = "flex"
      document.getElementById("projects").style.display = "none"
    }
  },
  {
    innerHTML: "My worlds",
    id: "projectsNav",
    onClick: (e) => {
      e.preventDefault()

      document.getElementById("lessons").style.display = "none"
      document.getElementById("projects").style.display = "flex"
    }
  },
  {
    innerHTML: "Profile",
    id: "profileNav",
    href: "profile.html"
  }
]

class Drawer extends AbstractComponent {
  constructor(elem) {
    super(elem)

    drawerElem.forEach(aLink => {
      let elem = this.elem,
      toggleActive = this.toggleActive

      const link = document.createElement("a")
      link.innerHTML = aLink.innerHTML

      link.classList.add("nav-link")
      aLink.id === "modulesNav" ? link.classList.add("active") : ""
      link.id = aLink.id
      
      link.href= aLink.href ? aLink.href : "#"

      !aLink.href && link.addEventListener("click", (e) => {
        aLink.onClick(e)
        toggleActive(elem, link)
      })

      this.elem.appendChild(link)
    })
  }

  toggleActive(drawer, selectedElem) {
    const navElems = drawer.querySelectorAll("a")

    // toggle base on condition
    navElems.forEach(elem => {
      elem.classList.toggle("active", elem === selectedElem)
    })
  }


}

module.exports = Drawer;