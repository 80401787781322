const CardsContainer = require('./CardsContainer')

class Lessons {
	constructor(elem) {
		this.elem = elem
		this.purchased = []
		this.progress = []
	}

	fetchDataFromServer() {
		// const purchasedPromise = database.ref(`payments/${localStorage.getItem("userId")}/history`)
		//   .once('value')
		//   .then(snapshot => {
		//     let purchased = []
		//     snapshot && snapshot.val() ? purchased = Object.values(snapshot.val()).map(aPurchased => {
		//       return aPurchased.sku
		//     }) : ''
		//     return purchased
		//   })

		const progressPromise = database
			.ref(`/progress/${localStorage.getItem('userId')}`)
			.once('value')
			.then((snapshot) => {
				return snapshot.val()
			})

		// return Promise.all([purchasedPromise, progressPromise])

		return Promise.all([progressPromise]).then((values) => {
			// this.purchased = values[0]
			this.progress = values[0]
		})
	}

	render() {
		this.fetchDataFromServer().then(() => {
			console.log(this.progress)

			let unowned = [],
				owned = []

			// if(this.purchased.includes('sku_00000')) {
			//   owned = Object.values(firstThree)
			// } else {
			//   for (let [key, value] of Object.entries(firstThree)) {
			//     if(this.purchased.includes(key)) {
			//       value.isPurchased = true
			//       owned.push(value)
			//     } else {
			//       value.isPurchased = false
			//       unowned.push(value)
			//     }
			//   }
			//   if(this.purchased.length === 0) {
			//     unowned.push(buyAll)
			//   }
			// }

			const cardContainerBeginner = new CardsContainer(this.elem)
			cardContainerBeginner.setupElements(
				lessonListIntro,
				this.progress,
				'Introduction to VR coding: 3D coordinates, variables, basic animation'
			)

			const cardContainerAdvanced = new CardsContainer(this.elem)
			cardContainerAdvanced.setupElements(
				lessionListAdvance,
				this.progress,
				'Intermediate Lessons: learning more coding concepts'
			)
		})
	}
}

let lessonListIntro = [
	{
		title: 'Module 1: Spaceship',
		module: 'module1',
		img: 'media/firstlesson.png',
		description:
			'For Beginning learners: Welcome to VR coding! In this lesson, you will animate 3D object in VR world using a coding concept called <b>variables.</b>',
	},
	{
		title: 'Module2: Traffic System',
		module: 'module2',
		img: 'media/roads.png',
		description:
			'For Beginning learners: You will learn 3D coding concepts such as <b>3D coordinates</b> and <b>variables</b> while making your own 3D traffic system.',
	},
	{
		title: 'Module 3: Waterway',
		module: 'module3',
		img: 'media/boat.png',
		description:
			"For Beginning learners: Let's make a boat get to the destination in a 3D world. In this lesson, you will learn basic coding concepts such as <b>variables</b>.",
	},
]
let lessionListAdvance = [
	{
		title: 'Module 4 - Logical Statements (if)',
		module: 'module4',
		img: 'media/module_4_cover.png',
		description: "Let's learn about if statements",
	},
	{
		title: 'Module 5 - Logical Statements (if else)',
		module: 'module5',
		img: 'media/module_5_cover.png',
		description: "Let's learn about if else statements",
	},
	{
		title: 'Module 6 - Introduction to Loops',
		module: 'module6',
		img: 'media/module_6_cover.png',
		description: 'Introduction to loops',
	},
	{
		title: 'Module 7 - Exploring Loops',
		module: 'module7',
		img: 'media/module_7_cover.png',
		description: "Let's explore more about loops",
	},
	{
		title: 'Module 8 - Loops Challenge',
		module: 'module8',
		img: 'media/module_8_cover.png',
		description: 'Advanced loops challenge',
	},
	{
		title: 'Module 9 - Nested Loops',
		module: 'module9',
		img: 'media/module_9_cover.png',
		description: "Let's learn about nested loops",
	},
	{
		title: 'Module 10 - Functions',
		module: 'module10',
		img: 'media/module_10_cover.png',
		description: 'Introduction to functions',
	},
	{
		title: 'Module 11 - For Each Loops',
		module: 'module11',
		img: 'media/module_11_cover.png',
		description: 'Advanced challenge using for each loops',
	},
]

module.exports = Lessons
