const AbstractComponent = require("../AbstractComponent.js");


  /*
 * Calls stripe.confirmCardPayment which creates a pop-up modal to
 * prompt the user to enter extra authentication details without leaving your page
 */
const pay = (stripe, card, clientSecret) => {
  changeLoadingState(true);
// Initiate the payment.
// If authentication is required, confirmCardPayment will automatically display a modal
  stripe
    .confirmCardPayment(clientSecret, {
      payment_method: {
        card: card
      }
    })
    .then(function(result) {
      if (result.error) {
        // Show error to your customer
        showError(result.error.message);
      } else {
        // The payment has been processed!
        orderComplete(stripe, clientSecret);
      }
    });
};

/* ------- Post-payment helpers ------- */

/* Shows a success / error message when the payment is complete */
const orderComplete = (stripe, clientSecret) => {
  // Just for the purpose of the sample, show the PaymentIntent response object
  stripe.retrievePaymentIntent(clientSecret)
  .then(function(result) {
    // var paymentIntent = result.paymentIntent;
    // var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);

    document.querySelector(".sr-payment-form").classList.add("hidden");
    // document.querySelector("pre").textContent = paymentIntentJson;

    document.querySelector(".sr-result").classList.remove("hidden");
    setTimeout(function() {
      document.querySelector(".sr-result").classList.add("expand");
    }, 200);
    
    setTimeout(() => {
      document.querySelector(".payment").classList.add("hidden");
      location.reload(true);
    }, 8000)

    // document.querySelector("#exit").addEventListener("click", () => {
    //   document.querySelector(".payment").classList.add("hidden")
    //   location.reload();

    // })

    changeLoadingState(false);
  });
}

const showError = (errorMsgText) => {
    changeLoadingState(false);
    var errorMsg = document.querySelector(".sr-field-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 4000);
  };


// Show a spinner on payment submission
const changeLoadingState = (isLoading) => {
  if (isLoading) {
    document.querySelector("button").disabled = true;
    document.querySelector(".spinner-border").classList.remove("hidden");
    document.querySelector("#button-text").classList.add("hidden");
  } else {
    document.querySelector("button").disabled = false;
    document.querySelector(".spinner-border").classList.add("hidden");
    document.querySelector("#button-text").classList.remove("hidden");
  }
};


class PaymentModal extends AbstractComponent {
  constructor(elem) {
    super(elem);
    this.stripe
  }

  async fetchPaymentInfo(products, currency) {    
    const requestBody = JSON.stringify({
      products: products,
      currency: currency
    })
    let token = await firebase.auth().currentUser.getIdToken()

    fetch(`${cloudURL}/payment`, 
    {
      method: "POST",
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: requestBody
    }).then((result) => {
      return result.json();
    })
    .then(data => {
      return this.setupElements(data)
    })
    .then(function({ stripe, card, clientSecret }) {
      document.querySelector("button").disabled = false;
  
      // Handle form submission.
      let form = document.getElementById("payment-form");
      form.addEventListener("submit", function(event) {
        event.preventDefault();
        // Initiate payment when the submit button is clicked
        pay(stripe, card, clientSecret);
      });
    }.bind(this))
    .catch(err => {
      console.log(err)
    })

  }

  setupElements(data) {
    this.stripe = Stripe(data.publishableKey);
    this.elem.classList.toggle("hidden")

    let orderDetail = this.elem.querySelector(".order-detail")

    orderDetail.innerHTML = `
      <h5>Module: ${data.item.name}</h5>
      <p>Price: CAD$${data.item.amount/100} </p>
    `

    let elements = this.stripe.elements();
    let style = {
      base: {
        color: "#fc5185",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    this.elem.addEventListener("click", (e) => {
      if(e.target.classList.contains("payment")) {
        e.target.classList.add("hidden")
      } else if (e.target.classList.contains("sr-root")) {
        e.target.parentElement.classList.add("hidden")
      }
    })

    let card = elements.create("card", { 
      hidePostalCode: true,
      style: style 
    });
    card.mount("#card-element");

    return {
      stripe: this.stripe,
      card: card,
      clientSecret: data.clientSecret
    };
  }
}


module.exports = PaymentModal;