const PUBLISHABLE_KEY = "pk_test_mWpbgDzZS030TBzeb7rvPLjk00Pgbtcq7K";

const checkoutRedirect = products => {
  const stripe = Stripe(PUBLISHABLE_KEY);
  const id = firebase.auth().currentUser.uid
  const email = firebase.auth().currentUser.email
  
  const requestBody = JSON.stringify({
    uid: id,
    email: email,
    products: products
  })

  fetch("https://us-central1-env3d-blockly-ca.cloudURLs.net/createSession", 
  // fetch("http://localhost:5000/env3d-blockly-ca/us-central1/createSession", 
  {
    method: "POST",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json"
    },
    body: requestBody
  }).then(function(result) {
    return result.json();
  }).then(json => {
      stripe
      .redirectToCheckout({
        sessionId: json.sessionId
      })
  // .then(handleResult);
  }).catch(err => {
    console.log(err)
  });

}

module.exports = { checkoutRedirect }