exports.createStripeCustomer = async () => {
  let token = await firebase.auth().currentUser.getIdToken()

  return fetch(`${cloudURL}/create-customer`, {
    method: 'post',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    return response.json();
  })
  .then(result => {
    // result.customer.id is used to map back to the customer object
    // result.setupIntent.client_secret is used to create the payment method
    return result;
  });
}

