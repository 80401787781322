const AbstractComponent = require("../AbstractComponent");
const pricingInfo = [
  {
    title: "Monthly",
    price: "$5",
  },
  {
    title: "Annual",
    price: "$50",
  }
]

class Pricing extends AbstractComponent {
  constructor(elem) {
    super(elem);
    this.cards = [];
    this.form;
  }

  setupElement() {
    pricingInfo.forEach(aPrice => {
      const cardContainer = document.createElement("div")
      cardContainer.className = "col col-md-3"

      cardContainer.appendChild(this.createPriceCard(aPrice))
      this.cards.push(cardContainer)

      this.elem.appendChild(cardContainer)
    })
  }

  createPriceCard(info) {
    const cards = this.cards
    let aCard = document.createElement("div");

    aCard.className = "card";
    aCard.setAttribute("type", info.title)

    aCard.innerHTML = `
      <div class="card-body">
        <h3 class="card-title">${info.title}</h3>
        <p class="card-text">${info.price}</p>
      </div>
    `;
    const choseBtn = document.createElement("button");
    if (!this.user) {
      choseBtn.classList.add("outline")
      choseBtn.innerHTML = "Choose this plan"
    } else {
      const currentType = this.user.type
      console.log(currentType)
      if (currentType === info.title) {
        choseBtn.innerHTML = "Current"
        choseBtn.disabled = true
      } else {
        choseBtn.classList.add("outline")
        choseBtn.innerHTML = "Choose this plan"
      }
    }


    aCard.querySelector(".card-body").appendChild(choseBtn)

    // handle choosen plan
    choseBtn.addEventListener("click", e => {
      e.preventDefault();

      // reset all cards to init state
      cards.forEach(aCard => {
        const button = aCard.querySelector("button")
        button.innerHTML !== "Current" ? button.innerHTML = "Choose this plan" : ""
        button.classList.add("outline")
      })

      // change chosen button to fill button and change text to chosen
      e.target.classList.remove("outline")
      e.target.innerHTML = "Chosen"

      if (!this.user) {
        // if the form isn't init yet, create a form component
        if (!this.form) {
          this.form = Component.Supscription.CheckoutForm.createComponent(document.querySelector("#subscription-form"))

          this.form.init()
        }

        // get chosen plan
        this.form.chosen = e.target.closest(".card").getAttribute("type")

        const detailPara = this.form.elem.closest(".card").querySelector(".card-text")

        detailPara.innerHTML = `You have choosen: <b>${this.form.chosen} license</b>`

        // remove hidden class when chosing a plan for the first time
        document.querySelector(".card.hidden") && document.querySelector(".card.hidden").classList.remove("hidden")

      } else {
        if (!this.form) {
          this.form = Component.Supscription.UpdateSubscription.createComponent(document.querySelector("#form"))

          this.form.user = this.user;
          this.form.chosen = e.target.closest(".card").getAttribute("type")

          this.form.init()
        }

      }


    })

    return aCard
  }


}

module.exports = Pricing