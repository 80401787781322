let FlexWindow = require('./FlexWindow');
let AbstractComponent = require('../AbstractComponent');

class Env3dWindow extends FlexWindow {
//class Env3dWindow extends AbstractComponent {
    constructor(elem) {
        super(elem);
        this.elem.querySelector('iframe').addEventListener('load', this.refresh.bind(this));
    }

    reload() {
        this.elem.querySelector('iframe').contentWindow.location.reload();
    }
    
    refresh() {
        var iframe = this.elem.querySelector('iframe');
        var innerDoc = iframe.contentWindow.document;
        
        // remove the VR button if fullscreen mode is not available
        var fs = document.body.requestFullScreen ||
                 document.body.webkitRequestFullScreen ||
                 document.body.mozRequestFullScreen;
        if (!fs) {
            innerDoc.getElementById('vr').style.display = 'none';
        }
        var code = document.createElement('script');
        code.innerHTML = "(function() { \n"
                       + document.getElementById("generated_code").textContent
                       + "})();";
        //console.log(code);
        innerDoc.body.appendChild(code);
    }
}

module.exports = Env3dWindow;
