const CardsContainer = require("./CardsContainer");

const apiLocation = myLocation + "/progress/";

const { checkoutRedirect } = require("../StripePayment/stripe");

class Products {
  constructor(elem) {
    this.elem = elem;
    this.choosen = [];
  }

  fetchDataFromServer() {
    return new Promise(function(resolve, reject) {
      firebase.auth().onAuthStateChanged(function(user) {
        const requestBody = JSON.stringify({
          uid: user.uid,
          isPurchased: false
        })
          fetch(`${cloudURL}/retrieveModuleInfo`, 
          // fetch("http://localhost:5000/env3d-blockly-ca/us-central1/retrieveModuleInfo", 
          {
            method: "POST",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/json"
            },
            body: requestBody
          })
          .then(function(response) {
            if(response !== undefined) {
              return response.json();
            } else {
              reject(response)
            }
          })
          .then(function(json) {
            console.log("Response from fetch: ", json);
            resolve(json);
          })
          .catch(function(resp) {
            reject(resp);
          });
      })
    })
  }

  handleChoosen(e, sku) {
    if(e.target.closest(".item").classList.contains("choosen")) {
      // quick way to remove item from array
      let index = this.choosen.indexOf(sku);
      if (index !== -1) this.choosen.splice(index, 1);
    } else {
      this.choosen.push(sku)
    }
    e.target.closest(".item").classList.toggle("choosen")

    console.log(this.choosen)
  }

  render(products, heading) {  
    this.fetchDataFromServer().then(data => {
      console.log(data)
      let cardsContainer = new CardsContainer(this.elem);
      cardsContainer.setupElements(data, undefined, heading, this.handleChoosen.bind(this));

      let button = document.createElement("button");
      button.innerHTML = "Process to payment";
  
      this.elem.appendChild(button);
      
      button.addEventListener("click", (e) => {
        checkoutRedirect(this.choosen)
      })
    });


  }

}

module.exports = Products