const AbstractComponent = require('../AbstractComponent.js');


const drawStar = (aNum) => {
  let result = "";
  for (let i = 1; i <= aNum; i++) {
    result += `<i class="fas fa-star"></i>`
  }
  for (let i = aNum; i < 5; i++) {
    result += `<i class="far fa-star"></i>`
  }

  return result;
}

const createDifficultyElem = (difficulty) => {
  let result = "";
  if(Array.isArray(difficulty)) {
    for (let i = 0; i < difficulty.length; i++) {
      result += drawStar(difficulty[i])
      if(i === 0) {
        result += " to "
      }
    }
  } else {
    result = drawStar(difficulty)
  }
  return result
}

const cardCol4 = (link, {title, description, img, difficulty}) => {
  return `
    <div class="module-link card">
      <img class="lesson-thumbnail" src="${img}">
      <div class="card-body">
        <h3 class="card-title">${title}</h3>
        <p class="description">${description}</p>
          <a href=${link}>
            Start
          </a>
      </div>
    </div>
  `
}

const cardCol12 = ({isPurchased, title, price, text, img, difficulty, time, sku}) => {
  return `
    <div class="module-link card ${sku==="sku_00000" ? "special": ""}">
      <div class="card-body row">
      <div class="item col-12 col-md-4">
        <img src="${img}">
      </div>
        <div class="col-12 col-md-8">
          <h3 class="card-title">${sku==="sku_00000" ? "<span>The most popular!" : ""} </span>${title}</h3>
          <p class="price"> ${price}</p>
          <p><b>Difficulty: </b>${createDifficultyElem(difficulty)}</p>
          <p class="description">${text}</p>
          <p class="time"><b>Lession time:</b> ${time}</p>
            <a href=#>
              Get this lesson
            </a>
        </div>
      </div>
    </div>
  `
}

class CardWithPicture extends AbstractComponent{
  constructor(elem) {
    super(elem);
  }

  
  setupElements(link, data) {
    // let {isPurchased} = data
    
    // this.elem.innerHTML = isPurchased ? cardCol4(link, data) : cardCol12(data)
    this.elem.innerHTML = cardCol4(link, data) 
  }


}

module.exports = CardWithPicture;

